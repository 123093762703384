function getBaseURL() {
    if (typeof window !== 'undefined') {
        return `${window.location.protocol}//${window.location.host}`;
    }
    return process.env.REACT_APP_LOCALHOST; // Fallback para el servidor
}

function generatePackageDetailsLink(packageName) {
    const BASE_URL = getBaseURL();
    return `${BASE_URL}/showdetails/${packageName}/0`;
}

function generateLeadsLink(leadId) {
    const BASE_URL = getBaseURL();
    return `${BASE_URL}/leads/${leadId}/1`;
}

function generateVerifyLink(packageName) {
    const BASE_URL = getBaseURL();
    return `${BASE_URL}/verify/${packageName}`;
}

function generateForgotPasswordLink(referenceNumber) {
    const BASE_URL = getBaseURL();
    return `${BASE_URL}/forgotpassword/${referenceNumber}`;
}

function generateShowProDetailsLink(packageName, userId) {
    const BASE_URL = getBaseURL();
    return `${BASE_URL}/showprodetails/${packageName}/0?invitee=${userId}&email=&type=500001&id=${userId}`;
}

function generateSignupLink(userId, email) {
    const BASE_URL = getBaseURL();
    return `${BASE_URL}/signup?invitee=${userId}&email=&type=500001&id=${userId}`;
}

function generateVideoListLink(packageName) {
    const BASE_URL = getBaseURL();
    return `${BASE_URL}/responses/${packageName}/0`;
}

export const LinkGeneratorHelpers = {
    generatePackageDetailsLink,
    generateLeadsLink,
    generateVerifyLink,
    generateForgotPasswordLink,
    generateShowProDetailsLink,
    generateSignupLink,
    generateVideoListLink
}; 
