/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, useRef, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {loadGoogleMapsAPI} from '../../utils/loadGoogleMapsAPI';
import AlertMessage from './AlertMessage';
import Axios from 'axios';
import * as formValidator from '../../utils/formValidation';
import Header from './Header';
import MailingAddressForm from './MailingAddressForm';
import PageTitle from './PageTitle';
import useGooglePlaceAutoComplete from "../../service/google_place_autocomplete";
import getSvg from '../../utils/getSvg';
import logoApple from '../../images/logo-apple.png';
import logoAutodesk from '../../images/logo-autodesk.png';
import logoDji from '../../images/logo-dji.png';
import logoDroneDeploy from '../../images/logo-drone-deploy.png';
import logoGoPro from '../../images/logo-gopro.png';
import logoLeica from '../../images/logo-leica.png';
import logoRicoh from '../../images/logo-ricoh.png';
import logoTelesin from '../../images/logo-telesin.png';
import uuid from 'react-uuid';
import AppContext from '../../AppContext';
import PayPalForm from './PayPalForm';
import {signUp} from '../../utils/authHelpers';

export default function CreateSurveyorAccounts() {
    const navigate = useNavigate();
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [company, setCompany] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [creditCardModalOpened, setCreditCardModalOpened] = useState(false);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [hasAddress, setHasAddress] = useState(false);
    const [hasCity, setHasCity] = useState(true);
    const [hasCompanyName, setHasCompanyName] = useState(false);
    const [hasConfirmPassword, setHasConfirmPassword] = useState(false);
    const [hasEmail, setHasEmail] = useState(false);
    const [hasFirstName, setHasFirstName] = useState(false);
    const [hasLastName, setHasLastName] = useState(false);
    const [hasPassword, setHasPassword] = useState(false);
    const [hasPayPalInfo, setHasPayPalInfo] = useState(true);
    const [hasPhoneNumber, setHasPhoneNumber] = useState(false);
    const [hasState, setHasState] = useState(true);
    const [hasZip, setHasZip] = useState(true);
    const [isInvitation, setIsInvitation] = useState(false);
    const [id, setId] = useState(null);
    const [isPaymentApproved, setIsPaymentApproved] = useState(false);
    const [paymentReferenceId, setpaymentReferenceId] = useState('');
    const [lastName, setLastName] = useState('');
    const [license, setLicense] = useState('');
    const [messageContent, setMessageContent] = useState('Account created successfully.');
    const [messageType, setMessageType] = useState('success');
    const [password, setPassword] = useState('');
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [payPalInfo, setPayPalInfo] = useState('');
    const [payPalPaymentType, setPayPalPaymentType] = useState('phone');
    const [phone, setPhone] = useState('');
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showCreditCardModal, setShowCreditCardModal] = useState(false);
    const [showAlreadyExists, setShowAlreadyExists] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [type, setType] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [usePayPal, setUsePayPal] = useState(false);
    const [queryParams, setQueryParams] = useState(new URLSearchParams());
    const [userId, setUserId] = useState('');
    const {inviteeId, setInviteeId, setUserType, setUserInfo, setAccessToken} = useContext(AppContext);

    const addressRef = useRef();
    const googleAutoCompleteSvc = useGooglePlaceAutoComplete();
    let autoComplete = "";

    const {setFocus, formState: {errors}} = useForm({});

    const closeAlertMessage = () => {
        setShowAlreadyExists(false);
        setShowConfirmation(false);
        setShowErrors(false);
    };

    useEffect(() => {
        sessionStorage.setItem('isLoggedIn', 'false');
        sessionStorage.setItem('userName', '')
        sessionStorage.setItem('userType', 0);
        sessionStorage.setItem('email', '');
        sessionStorage.setItem('isAdmin', false);
        sessionStorage.setItem('isPro', false);
        setQueryParams(new URLSearchParams(window.location.search));
        const querieParams = new URLSearchParams(window.location.search);
        setInviteeId(parseInt(querieParams.get('invitee')));
        const inviteeEmail = querieParams.get('email');


        if (typeof inviteeEmail !== 'undefined' && inviteeEmail !== null) {
            setEmail(inviteeEmail);
        } else {
            setEmail('');
        }

        if (querieParams.get('type') !== null && typeof querieParams.get('type') !== 'undefined') {
            setType(querieParams.get('type'));
        }

        if (querieParams.get('id') !== null && typeof querieParams.get('id') !== 'undefined') {
            setId(querieParams.get('id'));
        }

        if (inviteeId === '') {
            setIsInvitation(false);
        }

        async function initGooglePlaces() {
            try {
                await loadGoogleMapsAPI('initGoogleMaps');
                autoComplete = await googleAutoCompleteSvc.initAutoComplete(addressRef.current, handleAddressSelect);
            } catch (error) {
                console.error('Error loading Google Maps API:', error);
            }
        }

        initGooglePlaces();
        setHasCompanyName(true);
        setHasFirstName(true);
        setHasLastName(true);
        setHasEmail(true);
        setHasPhoneNumber(true);
        setHasPassword(true);
        setHasConfirmPassword(true);
        setHasAddress(true);
        setHasZip(true);
        setHasCity(true);
    }, [userId]);

    const handleTransactionApproval = (approval) => {
        //{ isApproved: true}
        if (approval?.isApproved) {
            setIsPaymentApproved(true);
            setpaymentReferenceId(approval.additionalData?.paypal_id);
            toggleCreditCardModal(true, approval.additionalData?.paypal_id);
        }
    };

    const closeOpenTransactions = async (referenceNumber, type, serviceType) => {
        const response = await Axios.post(`${process.env.REACT_APP_LOCALHOST}/closeOpenTransaction`, {
            transactionCode: referenceNumber,
            type: type,
            serviceType: serviceType
        });
    };

    /*const paySurveyorRewards = async (inviteeId, userId, referenceNumber, surveyorAmountPaid, packageName, packageId) => {
        const response = await Axios.post(`${process.env.REACT_APP_LOCALHOST}/payRewards`, { userId: userId, inviteeId: inviteeId, transactionCode: referenceNumber, uniqueIdentifier: referenceNumber, surveyorAmountPaid: surveyorAmountPaid, packageName: packageName, packageId: packageId, transactionType: 5 });
        // sendRewardsErrorEmail(userId, response.data.data.transactionCode, amountPaid, packageName, response.data.data.surveyor);
    };*/

    const sendRewardsErrorEmail = (userId, transactionCode, amountPaid, packageName, surveyor) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/sendErrorRewardsEmail`, {
            "userId": userId,
            "transactionCode": transactionCode,
            "amountPaid": amountPaid,
            "packageName": packageName,
            "surveyor": surveyor
        }).then((response) => {
            return response.data;
        }).catch(function (error) {
            console.log('sendRewardsErrorEmail = ', error);
        })
    }

    const toggleCreditCardModal = async (isApproved, referenceId) => {
        try {
            const userId = sessionStorage.getItem('userId');
            let isActive = 1;
            if (isApproved) {
                sessionStorage.setItem('userType', 500001);
                setUserType(500001);
                const responseUser = await Axios.post(`${process.env.REACT_APP_LOCALHOST}/makeUserActive`, {
                    userId: userId,
                    isActive: isActive
                });
                if (typeof responseUser.data !== 'undefined') {
                    const response = await getUserInfo(userId);
                    if (response.isActive === 1) {
                        sessionStorage.setItem('userName', `${response.firstName}`);
                        sessionStorage.setItem('userType', response.userType);
                        sessionStorage.setItem('email', response.email);
                        sessionStorage.setItem('isAdmin', response.isAdmin);
                        sessionStorage.setItem('isLoggedIn', true);
                        setShowCreditCardModal(false);
                        const referenceNumber = referenceId ? referenceId : paymentReferenceId ? paymentReferenceId : uuid();
                        setCreditCardModalOpened(false);
                        /*if (typeof queryParams !== 'undefined' && queryParams.get('invitee') !== null && typeof queryParams.get('invitee') !== 'undefined') {
                            // const haspaid = await Axios.post(`${process.env.REACT_APP_LOCALHOST}/getUserInfoByInviteeId`, {inviteeId: id});
                            // if(haspaid.data.length <= 1) { 
                            paySurveyorRewards(inviteeId, userId, referenceNumber, parseInt(process.env.REACT_APP_COST_TO_BUY_COURSES) * 100, null, null);
                            // }
                        }*/
                        navigate('/survey');
                    }
                }
            } else {
                isActive = 0;
                sessionStorage.setItem('userType', 0);
                sessionStorage.setItem('isLoggedIn', false);
            }
        } catch (error) {
            setShowCreditCardModal(false);
            console.log('Error making user active = ', error);
        }
    };

    const checkForm = () => {
        let isValid = true;

        if (formValidator.checkEmail(email)) {
            setHasEmail(true);
        } else {
            setHasEmail(false);
            isValid = false;
        }
        if (formValidator.checkCity(city)) {
            setHasCity(true);
        } else {
            setHasCity(false);
            isValid = false;
        }
        if (formValidator.checkZip(zip)) {
            setHasZip(true);
        } else {
            setHasZip(false);
            isValid = false;
        }
        if (formValidator.checkState(state)) {
            setHasState(true);
        } else {
            setHasState(false);
            isValid = false;
        }
        if (formValidator.checkFirstName(firstName)) {
            setHasFirstName(true);
        } else {
            setHasFirstName(false);
            isValid = false;
        }
        if (formValidator.checkLastName(lastName)) {
            setHasLastName(true);
        } else {
            setHasLastName(false);
            isValid = false;
        }
        if (formValidator.checkPhoneNumber(phone)) {
            setHasPhoneNumber(true);
        } else {
            setHasPhoneNumber(false);
            isValid = false;
        }
        if (formValidator.checkPassword(password)) {
            setHasPassword(true);
        } else {
            setHasPassword(false);
            isValid = false;
        }
        if (formValidator.checkConfirmPassword(confirmPassword)) {
            setHasConfirmPassword(true);
        } else {
            setHasConfirmPassword(false);
            isValid = false;
        }
        if (formValidator.checkBothPasswords(password, confirmPassword)) {
            setPasswordMatch(true);
        } else {
            setPasswordMatch(false);
            isValid = false;
        }
        if (password !== confirmPassword) {
            isValid = false;
        }
        if (usePayPal) {
            if (payPalPaymentType === 'phone') {
                if (formValidator.checkPhoneNumber(payPalInfo)) {
                    setHasPayPalInfo(true);
                } else {
                    setHasPayPalInfo(false);
                    isValid = false;
                }
            } else {
                if (formValidator.checkEmail(payPalInfo)) {
                    setHasPayPalInfo(true);
                } else {
                    setHasPayPalInfo(false);
                    isValid = false;
                }
            }
        }

        if (typeof address !== 'undefined' && typeof address !== null) {
            if (addressRef.current.value.trim().length === 0) {
                isValid = false;
                setHasAddress(false);
            } else {
                setHasAddress(true);
            }
        }
        return isValid;
    }

    const getUserInfo = async (userId) => {
        return await Axios.post(`${process.env.REACT_APP_LOCALHOST}/getUserProfile`, {userId: userId}).then((response) => {
            return response.data[0];
        }).catch(function (error) {
            console.log('tags error = ', error);
        })
    }

    const createNewAccount = () => {
        setShowErrors(false);
        // if (!creditCardModalOpened) {
        if (checkForm()) {
            signUp({
                email: email,
                address: addressRef.current.value,
                address2: address2,
                city: city,
                state: state,
                zip: zip,
                firstName: firstName,
                lastName: lastName,
                company: company,
                phone: phone,
                license: license,
                password: password,
                userType: 500001,
                isInvitation: isInvitation,
                inviteeId: inviteeId,
                usePayPal: usePayPal,

                // TODO: Duplicate values
                payPalPaymentType: payPalPaymentType,
                payPalType: payPalPaymentType,

                // TODO: Duplicate values
                payPalInfo: payPalInfo,
                payPalTypeValue: payPalInfo,

                type: type,
                invitationType: 'surveyor',
            }).then(async (response) => {
                if (typeof response !== 'undefined' && response !== null) {
                    if (response.msg === 'email already exists') {
                        setMessageType('error');
                        setMessageContent('Account already exists with the email provided.');
                        setShowAlreadyExists(true);
                    } else {
                        sessionStorage.setItem('userId', response.userId);
                        setUserId(response.userId);
                        setUserInfo(response);
                        response?.accessToken && setAccessToken(response.accessToken);

                        const user = await getUserInfo(response.userId);

                        console.log(user);

                        if (user.isActive === 1) {
                            sessionStorage.setItem('userName', `${user.firstName}`);
                            sessionStorage.setItem('userType', user.userType);
                            sessionStorage.setItem('email', user.email);
                            sessionStorage.setItem('isAdmin', user.isAdmin);
                            sessionStorage.setItem('isLoggedIn', true);
                            navigate('/survey');
                        }


                        // setCreditCardModalOpened(true);
                        // setShowCreditCardModal(true);
                    }
                } else {
                    console.log('it did not work');
                }
            }).catch(function (error) {
                console.log('catch error = ', error);
            });
        } else {
            setShowErrors(true);
        }
        // } else {
        //     setShowCreditCardModal(true);
        // }
    }

    const getUserProfile = async (userId) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getUserInfoById`, {userId: userId}).then((response) => {
            if (response.data.length > 0) {
                if (response.data[0].userType === 500001) {
                    setIsInvitation(true);
                    // setInviteeId(response.data[0].userId);
                } else {
                    setIsInvitation(false);
                }
            } else {
                setIsInvitation(false);
            }
        });
    }

    const handleAddressSelect = async () => {
        let addressObj = await googleAutoCompleteSvc.getFullAddress(autoComplete);
        addressRef.current.value = addressObj.address1;
        setAddress(addressObj.address1);
        setCity(addressObj.locality);
        setState(addressObj.adminArea1Short);
        setZip(addressObj.postalCode);
        setFocus("address2");
        setHasAddress(true);
        setHasCity(true);
        setHasState(true);
        setHasZip(true);
    };

    const handlePayPalTypeChange = (event) => {
        setPayPalPaymentType(event.target.value);
    };

    const handleUsePayPal = (e) => {
        if (e.target.checked) {
            setPayPalPaymentType('phone');
            setPayPalInfo('');
            setUsePayPal(false);
        } else {
            setUsePayPal(true);
        }
    }

    return (
        <div id="fazzad-site-container">
            <Header altLogo={true}/>
            <main id="fazzad-main">
                <div className='fz-full-width fz-value'>

                    <div id="page-title">
                        {/*<h1 className="h5 signup-step-tag">Step 1 in a remodeling project</h1>*/}
                        <h1 className="h3">Build your as-built survey business!</h1>
                        {/*<p>Get access to tools and more for ${process.env.REACT_APP_COST_TO_BUY_COURSES}.00/yr.</p>*/}

                        <figure>
                            <img
                                className="value-video"
                                data-modal="modal-7"
                                src="https://fazzad-courses.s3.us-west-1.amazonaws.com/tutorials/thumbnails/photo-00-intro.png"
                                alt="Video clip"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowVideoModal(true);
                                }}
                            />
                        </figure>
                        <button
                            type="button"
                            onClick={() => {
                                window.open('https://www.fazzad.pro/showdetails/3db42782-7d5f-4fb2-b72b-35e52d9927d0/0', '_blank')
                            }}
                            className="button-m button-primary">
                            <span className="text">Demo a Survey Package</span>
                        </button>
                    </div>

                    <div className="fz-banner">
                        <div className="value-prop">
                            {/*<div className="value-item">*/}
                            {/*    <span className="fz-circle">*/}
                            {/*        {getSvg('video-play')}*/}
                            {/*    </span>*/}
                            {/*    <div className="description">*/}
                            {/*        <h3>Learn</h3>*/}
                            {/*        <h5>Learn from other Pros.</h5>*/}
                            {/*        <ul>*/}
                            {/*            <li>Watch videos and learn how to build a successful surveyor business</li>*/}
                            {/*            <li>Upgrade your survey tools, marketing approach, digital invoicing and more</li>*/}
                            {/*        </ul>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="value-item">
                                <span className="fz-circle">
                                    {getSvg('cloud-download')}
                                </span>
                                <div className="description">
                                    <h3>Deliver</h3>
                                    <h5>Use Fazzad to deliver and present your as-built packages to your clients.</h5>
                                    <ul>
                                        <li>1 year of as-built survey package hosting</li>
                                        <li>$20 per as-built package upload</li>
                                        <li>Online DWG viewer</li>
                                        <li>Online Revit viewer</li>
                                        <li>Online Still photography viewer</li>
                                        <li>Online 360 photography viewer</li>
                                        <li>Online PDF viewer</li>
                                        <li>Plan &amp; Picture</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="value-item">
                                <span className="fz-circle">
                                    {getSvg('network')}
                                </span>
                                <div className="description">
                                    <h3>Connect</h3>
                                    <h5>Build your network of Pros and Vendors. Offer your clients a comprehensive and
                                        hassle-free remodeling solution.</h5>
                                    <ul>
                                        <li>Your as-built package connects Pros &amp; Vendors to your client</li>
                                        <li>Send invitation emails to Pros & Vendors (pre-generated)</li>
                                        <li>Grow your network of Pros &amp; Vendors with different trades and services
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="value-item">
                                <span className="fz-circle">
                                    {getSvg('money')}
                                </span>
                                <div className="description">
                                    <h3>Earn</h3>
                                    <h5>Everytime your network contacts the client, you earn commission**. There are two
                                        options available to Pros &amp; Vendors:</h5>
                                    <ul>
                                        <li>**FREE contact: when a Pro or Vendor selects the video submission option
                                            (video upload required)
                                        </li>
                                        <li>**PAID: when a Pro or Vendor declines the video submission option and
                                            instead, pays to contact the client, you earn comission
                                        </li>
                                        <li>One more thing… The first 5 submissions are free for the PAID option</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section id="requirements-container">
                        <h2 className="h4">Required</h2>
                        <div className="requirements-feed">
                            <div className="requirements rounded">
                                <h4 className="h5">Transportation</h4>
                                <ul className="item-list">
                                    <li className="item">Reliable means of transportation</li>
                                </ul>
                            </div>
                            <div className="requirements rounded">
                                <h4 className="h5">Must have equipment</h4>
                                <ul className="item-list">
                                    <li className="item">
                                        <a href="https://www.ricoh360.com/theta/">Ricoh Theta Z1</a>
                                    </li>
                                    <li className="item">Iphone 15</li>
                                    <li className="item">
                                        <a target="_blank"
                                           href="https://www.amazon.com/TELESIN-Magnetic-Shoulder-Necklace-Insta360/dp/B09X33WBRD">Telesin</a>
                                    </li>
                                    <li>Laptop equipped with AutoCAD</li>
                                    <li>Utility Cart for laptop</li>
                                    <li className="item">Tape measure</li>
                                    <li className="item">Door stop</li>
                                    <li>Leico DISTO D2 Laser Distance Measure</li>
                                </ul>
                            </div>
                            <div className="requirements rounded">
                                <h4 className="h5">Additional equipment (optional)</h4>
                                <ul className="item-list">
                                    <li>Laser scanner (<a target="_blank"
                                                          href="https://shop.leica-geosystems.com/leica-blk/blk360/technology">BLK360
                                        G2</a>)
                                    </li>
                                    <li className="item">iPad for Laser Scanner</li>
                                    <li className="item">Construction lights</li>
                                    <li className="item">Drone (compatible with DroneDeploy)</li>
                                </ul>
                            </div>
                            <div className="requirements rounded">
                                <h4 className="h5">Subscriptions</h4>
                                <ul className="item-list">
                                    <li className="item">For the Leica BLK360 G2 Laser Scanner:</li>
                                    <ul>
                                        <li>
                                            <a target="_blank"
                                               href="https://shop.leica-geosystems.com/leica-blk/leica-cyclone-register-360-plus">Cyclone
                                                Register 360</a>
                                        </li>
                                        <li><a target="_blank"
                                               href="https://shop.leica-geosystems.com/leica-blk/blk360/blk360-g1-software">Cyclone
                                            Field 360</a></li>
                                    </ul>
                                    <li className="item">DroneDeploy account</li>
                                </ul>
                            </div>
                            <div className="requirements rounded">
                                <h4 className="h5">Additional requirements</h4>
                                <ul className="item-list">
                                    <li className="item">Commercial buildings require COI, Certificate of Insurance</li>
                                    <li className="item">Worker's Comp (if you're the owner, you can be exempt from
                                        WC)
                                    </li>
                                    <li>Background checks (posted publicly)</li>
                                </ul>
                            </div>
                        </div>
                        <div className="partner-container">
                            <ul>
                                <li>
                                    <img src={logoRicoh} alt="Ricoh Theta Z1"/>
                                </li>
                                <li>
                                    <img src={logoApple} alt="Apple"/>
                                </li>
                                <li>
                                    <img src={logoTelesin} alt="Telesin - Magnetic shoulder necklace"/>
                                </li>
                                <li>
                                    <img src={logoAutodesk} alt="CAD - Autodesk"/>
                                </li>
                                <li>
                                    <img src={logoGoPro} alt="GoPro - Chesty strap"/>
                                </li>
                                <li>
                                    <img src={logoLeica} alt="Leica - BLK360 G2 scanner"/>
                                </li>
                                <li>
                                    <img src={logoDroneDeploy} alt="DroneDeploy"/>
                                </li>
                                <li>
                                    <img src={logoDji} alt="DJI"/>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>
                <div id="app-container">
                    {showCreditCardModal ?
                        <>
                            <PayPalForm
                                modalHeader='Sign Up'
                                modalSubHeader='Enter your credit card information to join Fazzad.'
                                amountPaid={parseInt(process.env.REACT_APP_COST_TO_BUY_COURSES) * 100}
                                onClose={() => {
                                }}
                                userId={userId}
                                userInfo={{
                                    city,
                                    zip,
                                    state,
                                    address
                                }}
                                serviceType={4}
                                onTransactionApproval={handleTransactionApproval}
                                additionalData={
                                    {"inviteeId": inviteeId}
                                }
                            />
                        </>
                        : ''
                    }
                    <div className="form-container" id="proprofile">

                        <section className="form-details fz-block">
                            <h2 className="h4">Account details</h2>
                            <div className="form-row col-2">
                                <div className={`form-field text-input ${!hasCompanyName ? 'error' : ''}`}>
                                    <label htmlFor="company">Company Name (Optional)</label>
                                    <input
                                        type="text"
                                        id="company"
                                        value={company}
                                        onBlur={(e) => {
                                            formValidator.clearEmojis(e.target.value, 'company');
                                        }}
                                        onFocus={(e) => {
                                            setHasCompanyName(true);
                                        }}
                                        onChange={(e) => {
                                            setCompany(e.target.value);
                                        }}
                                    />
                                    {!hasCompanyName ?
                                        <p className="validation-message">Enter company name.</p>
                                        : ''
                                    }
                                </div>
                            </div>
                            <div className="form-row col-2">
                                <div className={`form-field text-input ${!hasFirstName ? 'error' : ''}`}>
                                    <label htmlFor="firstName">First Name</label>
                                    <input
                                        id='firstName'
                                        name='firstName'
                                        type='text'
                                        value={firstName}
                                        onBlur={(e) => {
                                            formValidator.clearEmojis(e.target.value, 'firstName');
                                        }}
                                        onFocus={(e) => {
                                            setHasFirstName(true);
                                            setShowErrors(false);
                                        }}
                                        onChange={(e) => {
                                            setFirstName(e.target.value);
                                        }}
                                    />
                                    {!hasFirstName ?
                                        <p className="validation-message">Enter first name.</p>
                                        : ''
                                    }
                                </div>
                                <div className={`form-field text-input ${!hasLastName ? 'error' : ''}`}>
                                    <label htmlFor="lastName">Last Name</label>
                                    <input
                                        id='lastName'
                                        name='lastName'
                                        type='text'
                                        value={lastName}
                                        onBlur={(e) => {
                                            formValidator.clearEmojis(e.target.value, 'lastName');
                                        }}
                                        onFocus={(e) => {
                                            setHasLastName(true);
                                            setShowErrors(false);
                                        }}
                                        onChange={(e) => {
                                            setLastName(e.target.value);
                                        }}
                                    />
                                    {!hasLastName ?
                                        <p className="validation-message">Enter last name.</p>
                                        : ''
                                    }
                                </div>
                            </div>
                            <div className="form-row col-2">
                                <div className={`form-field text-input ${!hasEmail ? 'error' : ''}`}>
                                    <label htmlFor="email">Email</label>
                                    <input
                                        id='email'
                                        type='email'
                                        autoComplete="one-time-code"
                                        value={email}
                                        onBlur={(e) => {
                                            formValidator.checkEmail(e.target.value);
                                        }}
                                        onFocus={(e) => {
                                            setHasEmail(true);
                                            setShowAlreadyExists(false);
                                            setShowErrors(false);
                                        }}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                    />
                                    {!hasEmail ?
                                        <p className="validation-message">Enter valid email.</p>
                                        : ''
                                    }
                                </div>
                                <div className={`form-field text-input ${!hasPhoneNumber ? 'error' : ''}`}>
                                    <label htmlFor="phone">Phone number (10 digits only)</label>
                                    <input
                                        id='phone'
                                        name='phone'
                                        type='tel'
                                        value={phone}
                                        maxLength={10}
                                        onBlur={(e) => {
                                            formValidator.formatPhoneNumber(e.target.value, setPhone);
                                        }}
                                        onChange={(e) => {
                                            setPhone(e.target.value);
                                        }}
                                        onFocus={(e) => {
                                            setHasPhoneNumber(true);
                                            setShowErrors(false);
                                        }}
                                    />
                                    {!hasPhoneNumber ?
                                        <p className="validation-message">Enter a 10 digits phone number.</p>
                                        : ''
                                    }
                                </div>
                            </div>
                            <div className="form-row col-2">
                                <div className={`form-field text-input ${!hasPassword ? 'error' : ''}`}>
                                    <label htmlFor="current-password">Password</label>
                                    <input
                                        id='current-password'
                                        type='password'
                                        autoComplete="one-time-code"
                                        onBlur={(e) => {
                                            formValidator.clearEmojis(e.target.value, 'password');
                                        }}
                                        onFocus={(e) => {
                                            setHasPassword(true);
                                            setHasConfirmPassword(true);
                                            setShowErrors(false);
                                        }}
                                        value={password} onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                    />
                                    {!hasPassword ?
                                        <p className="validation-message">Enter password.</p>
                                        : ''
                                    }
                                </div>
                                <div
                                    className={`form-field text-input ${!hasConfirmPassword || !passwordMatch ? 'error' : ''}`}>
                                    <label htmlFor="confirm-password">Confirm password</label>
                                    <input
                                        id='confirm-password'
                                        type='password'
                                        autoComplete="one-time-code"
                                        value={confirmPassword}
                                        onBlur={(e) => {
                                            formValidator.clearEmojis(e.target.value, 'confirmPassword');
                                        }}
                                        onFocus={(e) => {
                                            setHasConfirmPassword(true);
                                            setPasswordMatch(true);
                                            setShowErrors(false);
                                        }}
                                        onChange={(e) => {
                                            setConfirmPassword(e.target.value);
                                        }}
                                    />
                                    {!hasConfirmPassword ?
                                        <p className="validation-message">Enter matching confirm password.</p>
                                        : ''
                                    }
                                    {!passwordMatch ?
                                        <p className="validation-message">Passwords don't match.</p>
                                        : ''
                                    }

                                </div>
                            </div>
                        </section>

                        <section className="fz-block">
                            <MailingAddressForm
                                setAddress={setAddress}
                                addressRef={addressRef}
                                hasAddress={hasAddress}
                                setHasAddress={setAddress}
                                setAddress2={setAddress2}
                                address2={address2}
                                city={city}
                                setCity={setCity}
                                hasCity={hasCity}
                                setHasCity={setHasCity}
                                state={state}
                                setState={setState}
                                hasState={hasState}
                                setHasState={setHasState}
                                zip={zip}
                                setHasZip={setHasZip}
                                hasZip={hasZip}
                                setZip={setZip}
                                formValidator={formValidator}
                                showHeader={true}
                                disableAddress={false}
                            />
                        </section>
                        <section className='form-details fz-block'>
                            <h2 className="h4">Earn commissions</h2>
                            <p>By building your Fazzad network of Pros and Vendors, you can earn commissions when they
                                contact your clients. Enter your PayPal information to begin earning commissions.</p>
                            {/*<strong>Get Fazzad for free!*</strong>*/}
                            {/*<p>When you sign-up for the first-time, you can invite other Surveyors. If they sign-up, you*/}
                            {/*    get $100 for each new sign-up (you must have a PayPal account to qualify).</p>*/}
                            {/*<p>*/}
                            {/*    <small>*Offer limited to users that pay to sign-up.</small>*/}
                            {/*</p>*/}
                            <div className="form-checkbox fz-block">
                                <div className="form-field checkbox">
                                    <input
                                        type="checkbox"
                                        id="dontUsePayPal"
                                        name="dontUsePayPal"
                                        value="1"
                                        checked={!usePayPal}
                                        onChange={(e) => handleUsePayPal(e)}
                                    />
                                    <label htmlFor="dontUsePayPal">I'm not interested in earning commissions.</label>
                                </div>
                            </div>
                            {usePayPal && (
                                <div className="form-row col-2">
                                    <div className="form-radio">
                                        <div className="form-field radio">
                                            <div className='fz-block-sm'>
                                                <h5 className="">PayPal credentials</h5>
                                            </div>
                                            <input
                                                type="radio"
                                                name="payPalType"
                                                id="payPalPhone" // Unique id
                                                value="phone" // Value representing this option
                                                checked={payPalPaymentType === 'phone'} // Check against value
                                                onChange={handlePayPalTypeChange} // Call the function
                                            />
                                            <label htmlFor="payPalPhone">Phone number</label>
                                            <input
                                                type="radio"
                                                name="payPalType"
                                                id="payPalEmail" // Unique id
                                                value="email" // Value representing this option
                                                checked={payPalPaymentType === 'email'} // Check against value
                                                onChange={handlePayPalTypeChange} // Call the function
                                            />
                                            <label htmlFor="payPalEmail">Email address</label>
                                        </div>
                                        <div className={`form-field text-input ${!hasPayPalInfo ? 'error' : ''}`}>
                                            <label htmlFor="payPalInfo">Enter PayPal {payPalPaymentType}</label>
                                            <input
                                                type="text"
                                                id="payPalInfo"
                                                value={payPalInfo}
                                                autoComplete="one-time-code"
                                                maxLength={payPalPaymentType === 'phone' ? 10 : 100}
                                                onChange={(e) => setPayPalInfo(e.target.value)}
                                                onFocus={(e) => setHasPayPalInfo(true)}
                                            />
                                            {!hasPayPalInfo && (
                                                <p className="validation-message">Enter {payPalPaymentType}.</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </section>
                        <div className="button-group">
                            {!userId && <button
                                type="button"
                                className="button-m button-primary"
                                onClick={() => {
                                    createNewAccount()
                                }}
                            >
                                <span className="text">Sign up</span>
                            </button>}

                        </div>
                        <div className='fz-block-sm'></div>

                        {showConfirmation ?
                            <AlertMessage
                                messageType={messageType}
                                messageHeader={messageContent}
                                messageBody=''
                                onButtonClick={closeAlertMessage}
                            />
                            : ''
                        }
                        {showAlreadyExists ?
                            <AlertMessage
                                messageType='warning'
                                messageHeader='Account already exists with the email address provided.'
                                messageBody=''
                                onButtonClick={closeAlertMessage}
                            />
                            : ''
                        }
                        {showErrors ?
                            <AlertMessage
                                messageType='error'
                                messageHeader='Make sure to fill the form fields.'
                                messageBody=''
                                onButtonClick={closeAlertMessage}
                            />
                            : ''
                        }
                    </div>
                </div>
                {showVideoModal && (
                    <div id="modal-7" className="modal-container">
                        <div className="modal--video">
                            <button
                                type="button"
                                className="button-tertiary button-s button-icon modal-close"
                                onClick={() => setShowVideoModal(false)}
                            >
                                {getSvg('close')}
                            </button>
                            <video
                                src="https://fazzad-courses.s3.us-west-1.amazonaws.com/tutorials/Signup.mp4"
                                poster="https://fazzad-courses.s3.us-west-1.amazonaws.com/tutorials/thumbnails/photo-00-intro.png"
                                controls
                                autoPlay
                            />
                        </div>
                    </div>
                )}
            </main>
        </div>
    )
}
