/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import * as formValidator from '../../utils/formValidation';
import { cleanMediaUrl } from '../../utils/clearMediaUrl';
import { dateField } from '../../utils/dateFormat';
import { S3 } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import AlertMessage from '../libs/AlertMessage';
import Axios from 'axios';
import BreadCrumbs from '../libs/BreadCrumbs';
import getSvg from '../../utils/getSvg';
import Header from '../libs/Header';
import JobTags from '../libs/JobTags';
import MailingAddressForm from '../libs/MailingAddressForm';
import ModalInfo from '../libs/ModalInfo';
import ModalAlertMessage from '../libs/ModalAlertMessage';
import NoCoverImage from '../../images/NoCoverScopeImage.png';
import PageTitleWithSteps from '../libs/PageTitleWithSteps';
import S31 from "react-aws-s3";
import useGooglePlaceAutoComplete from "../../service/google_place_autocomplete";
import ProgressBar from '../libs/ProgressBar';
import { loadGoogleMapsAPI } from '../../utils/loadGoogleMapsAPI';
import axiosInstance from '../../utils/axiosInstance';
import { uploadWithSignedUrl } from "../../utils/awsHelpers";

window.Buffer = window.Buffer || require("buffer").Buffer;

export default function SurveyorEditPackage() {
    const { formState: { errors } } = useForm({});
    const { packageName } = useParams();
    sessionStorage.setItem('userType', 500001);
    sessionStorage.setItem('isPropertyOwner', 'false');
    const navigate = useNavigate();
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [coverImageName, setCoverImageName] = useState('');
    const [coverImageSource, setCoverImageSource] = useState('');
    const [scopeOfWorkSource, setScopeOfWorkSource] = useState('');
    const [coverImageInPackage, setCoverImageInPackage] = useState(0);
    const [currentCoverImageIndex, setCurrentCoverImageIndex] = useState(0);
    const [countScopeOfWorkVideo, setCountScopeOfWorkVideo] = useState(0);
    const [currentOption, setCurrentOption] = useState(null);
    const [currentScopeOfWorkVideo, setCurrentScopeOfWorkVideo] = useState('');
    const [dronePicturesInPackage, setDronePicturesInPackage] = useState(0);
    const [dropBoxLink, setDropBoxLink] = useState('');
    const [dwgPicturesInPackage, setDwgPicturesInPackage] = useState(0);
    const [fileProgressScopeOfWork, setFileProgressScopeOfWork] = useState(0);
    const [hasAddress, setHasAddress] = useState(true);
    const [hasCity, setHasCity] = useState(true);
    const [hasEmail, setHasEmail] = useState(true);
    const [hasFilesToBeUploaded, setHasFilesToBeUploaded] = useState(true);
    const [hasScopeOfWork, setHasScopeOfWork] = useState(true);
    const [hasStartDate, setHasStartDate] = useState(true);
    const [hasState, setHasState] = useState(true);
    const [hasZip, setHasZip] = useState(true);
    const [hasNoPackage, setHasNoPackage] = useState(false);
    const [homeOwnerEmail, setHomeOwnerEmail] = useState('');
    const [isPackageUpdated, setIsPackageUpdated] = useState(false);
    const [isValidScopeVideo, setIsValidScopeVideo] = useState(true);
    const [packages, setPackages] = useState([]);
    const [pdfsInPackage, setPdfsInPackage] = useState(0);
    const [scopeOfWork, setScopeOfWork] = useState('');
    const [scopeOfWorkVideo, setScopeOfWorkVideo] = useState('');
    const [scopeOfWorkInPackage, setScopeOfWorkInPackage] = useState(0);
    const [scopeOfWorkThumbnail, setScopeOfWorkVideoThumbnail] = useState('');
    const [selectedFileCoverImage, setSelectedFileCoverImage] = useState([]);
    const [selectedFileScopeOfWork, setSelectedFileScopeOfWork] = useState([]);
    const [selectedTags, setSelectedTags] = useState({ design: [], construction: [], });
    const [showNotAllowed, setShowNotAllowed] = useState(false);
    const [showNoFilesToBeUpdated, setShowNoFilesToBeUpdated] = useState(false);
    const [showWarningContainerMessage, setShowWarningContainerMessage] = useState(true);
    const [showWrongImageFormat, setShowWrongImageFormat] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [stillPicturesInPackage, setStillPicturesInPackage] = useState(0);
    const [uploadMoreFiles, setUploadMoreFiles] = useState(false);
    const [uploadStarted, setUploadStarted] = useState(false);
    const [state, setState] = useState('');
    const [showProcessing, setShowProcessing] = useState(false);
    const [threeSixtyPicturesInPackage, setThreeSixtylPicturesInPackage] = useState(0);
    const [projectType, setProjectType] = useState(2); // Default to 'residential'
    const [packagePrivate, setPackagePrivate] = useState(false);
    const [zip, setZip] = useState('');
    const addressRef = useRef();
    const folderName = process.env.REACT_APP_COVER_IMAGE;
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    const maxLength = 50000;
    const numberOfFilesToBeUploadedAtTime = 1;
    const picturesTypes = ['still-pictures', 'dwg-files', 'three-sixty-pictures', 'drone-pictures', 'pdf-files', 'cover-image'];
    const processingMessage = process.env.REACT_APP_PROCESSING_MESSAGE;
    const scopeOfWorkName = process.env.REACT_APP_SCOPE_OF_WORK;
    const s3BucketPrefix = process.env.REACT_APP_S3_BUCKET_PREFIX;
    const s3ThumbnailBucketPrefix = process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX;
    const s3BucketPrefixVideo = process.env.REACT_APP_S3_PROJECTS_VIDEOS_PREFIX;
    const scopeOfWorkRef = useRef(null);
    const thumbNailPrefix = process.env.REACT_APP_THUMBANAIL_PREFIX;
    const uniqueImage = process.env.REACT_APP_UNIQUE_IMAGE;
    const userId = parseInt(sessionStorage.getItem('userId'));
    const userType = parseInt(sessionStorage.getItem('userType'));
    const videoExtensionsRegex = /\.(mp4|mov|avi|wmv|flv|webm)$/i;
    const videoSourceBucket = process.env.REACT_APP_PROJECT_VIDEO_SOURCE;
    const fazzadProjectBucket = process.env.REACT_APP_S3_BUCKET;

    let autoComplete = "";
    let coverImageFileName = '';
    let tempCoverImageCounter = 0;

    const handleGoToLogin = () => navigate('/login');
    const googleAutoCompleteSvc = useGooglePlaceAutoComplete();

    useEffect(() => {
        if (isLoggedIn !== 'true') {
            handleGoToLogin();
        } else {
            setShowNotAllowed(false);
            getUserProfile(userId);
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const typeValue = urlParams.get('view');
            if (typeof typeValue !== 'undefined' && typeValue !== null && typeValue.toString() === 'true') {
                setIsPackageUpdated(true);
            }
            async function initGooglePlaces() {
                try {
                    await loadGoogleMapsAPI('initGoogleMaps');
                    autoComplete = await googleAutoCompleteSvc.initAutoComplete(addressRef.current, handleAddressSelect);
                } catch (error) {
                    console.error('Error loading Google Maps API:', error);
                }
            }

            initGooglePlaces();
        }
    }, []);

    const handleTextChange = (event) => {
        const inputText = event.target.value;
        if (inputText.length <= maxLength) {
            setScopeOfWork(inputText);
        }
    };

    const handleAlertButtonClick = () => {
        setShowWarningContainerMessage(false)
    };

    const calculatePercentage = (max, current) => {
        let total = 0;
        total = ((current / max) * 100);
        return Math.ceil(total);
    }

    const getPackageImage = async (pkName) => {
        if (typeof pkName !== 'undefined') {
            try {
                axiosInstance.post(`${process.env.REACT_APP_LOCALHOST}/uniqueImage`, { packageName: pkName, pictureType: scopeOfWorkName }).then((response) => {
                    if (response.data.length > 0) {
                        if (typeof response.data[0].Key !== 'undefined') {
                            setScopeOfWorkInPackage(response.data.length);
                            const videoNameArray = response.data[0].Key.split('/');
                            const videoName = videoNameArray[videoNameArray.length - 1].replace('jpg', 'mp4');
                            setScopeOfWorkVideoThumbnail(`${s3ThumbnailBucketPrefix}/${response.data[0].Key}`);
                            setScopeOfWorkVideo(`${s3BucketPrefixVideo}/${packageName}/${scopeOfWorkName}/${videoName}`);
                            setCurrentScopeOfWorkVideo(`${s3BucketPrefixVideo}/${packageName}/${scopeOfWorkName}/${videoName}`);
                        }
                    }
                }).catch(function (error) {
                    console.log(error)
                })
            } catch (err) {
                console.log('err = ', err);
            }
        }
    }

    const handleAddressSelect = async () => {
        let addressObj = await googleAutoCompleteSvc.getFullAddress(autoComplete);
        addressRef.current.value = addressObj.address1;
        setAddress(addressObj.address1);
        setCity(addressObj.locality);
        setState(addressObj.adminArea1Short);
        setZip(addressObj.postalCode);
    };


    const isTagSelected = (value, category) => {
        return selectedTags[category]?.includes(value);
    };

    const getFiles = async () => {
        try {
            picturesTypes.forEach((picturetype) => {
                Axios.post(`${process.env.REACT_APP_LOCALHOST}/${uniqueImage}`, { packageName: packageName, pictureType: picturetype }).then((response) => {
                    switch (picturetype) {
                        case 'still-pictures':
                            setStillPicturesInPackage(response.data.length);
                            break;
                        case 'dwg-files':
                            setDwgPicturesInPackage(response.data.length);
                            break;
                        case 'three-sixty-pictures':
                            setThreeSixtylPicturesInPackage(response.data.length);
                            break;
                        case 'drone-pictures':
                            setDronePicturesInPackage(response.data.length);
                            break;
                        case 'pdf-files':
                            setPdfsInPackage(response.data.length);
                            break;
                        case 'cover-image':
                            setCoverImageInPackage(response.data.length);
                            break;
                    }
                })
            })
        } catch (err) {
            console.log('found error ', err);
        }
    }

    const deleteCurrentScopeOfWorkVideo = async (packageName, bucketName) => {
        try {
            const parts = scopeOfWorkVideo.split('/');
            // const fileName = parts[parts.length - 1];
            Axios.post(`${process.env.REACT_APP_LOCALHOST}/deleteFilesFromPackage`, {
                isVideo: true,
                packageName: packageName,
                bucketName: bucketName,
            }).then((response) => {
                uploadVideos(selectedFileScopeOfWork[0], videoSourceBucket, packageName)
            }).catch(function (error) {
                console.log('error = ', error);
            });
        } catch (err) {
            console.log('found error ', err);
        }
    }

    const deleteThumbnail = async (packageName, bucketName) => {
        try {
            Axios.post(`${process.env.REACT_APP_LOCALHOST}/deleteThumbnailFromPackage`, {
                packageName: packageName,
                bucketName: bucketName,
            }).then((response) => {
                deleteCurrentScopeOfWorkVideo(packageName, process.env.REACT_APP_PROJECT_VIDEOS_BUCKET_NAME);
            }).catch(function (error) {
                console.log('error = ', error);
            });
        } catch (err) {
            console.log('found error ', err);
        }
    }

    const chooseMediaToEdit = (packageData) => {
        if (packageData !== null) {
            if (packageData.hasCoverImage > 0) {
                setCurrentOption('cover-image');
            } else if (packageData.scopeOfWorkInPackage === 1) {
                setCurrentOption('scope-of-work');
            } else if (packageData.hasStillPictures === 1) {
                setCurrentOption('still-pictures');
            } else if (packageData.has360Pictures === 1) {
                setCurrentOption('three-sixty-pictures');
            } else if (packageData.hasDronePictures === 1) {
                setCurrentOption('drone-pictures');
            } else if (packageData.hasDwg === 1) {
                setCurrentOption('cad-file');
            } else if (packageData.hasPdfs === 1) {
                setCurrentOption('pdf-files');
            }
        }
    }

    const getPackageEdit = async (packageName) => {
        try {
            Axios.post(`${process.env.REACT_APP_LOCALHOST}/getPackage`, { packageName: packageName }).then((response) => {
                setPackages(response.data);
                if (response.data.length > 0) {
                    if (response.data[0].userId !== userId) {
                        setShowNotAllowed(true);
                    } else {
                        setShowNotAllowed(false);

                        if (addressRef.current !== null) {
                            addressRef.current.value = response.data[0].packageAddress;
                        }
                        chooseMediaToEdit(response.data[0]);
                        getPackageImage(response.data[0].packageName);
                        setStartDate(dateField(response.data[0].packageStartDate));
                        setHomeOwnerEmail(response.data[0].homeOwnerEmail);
                        setAddress(response.data[0].packageAddress);
                        setAddress2(response.data[0].packageAddress2);
                        setCity(response.data[0].packageCity);
                        setCoverImageName(response.data[0].coverImage);
                        setState(response.data[0].packageState);
                        setZip(response.data[0].packageZip);
                        setScopeOfWork(response.data[0].scopeOfWork);
                        setProjectType(response.data[0].projectType);
                        setPackagePrivate(response.data[0].isPackagePrivate === 1);
                        setCoverImageSource(response.data[0].coverImage !== null ? `${s3BucketPrefix}/${response.data[0].packageName}/${folderName}/${response.data[0].coverImage}` : NoCoverImage);
                        if (response.data[0].scopeOfWorkVideo !== null) {
                            localStorage.setItem('scopeOfWorkVideoUrl', `${s3BucketPrefixVideo}/${response.data[0].packageName}/${scopeOfWorkName}/${cleanMediaUrl(response.data[0].scopeOfWorkVideo)}`);
                        }
                        setDropBoxLink(response.data[0].dropBoxLink);
                        const { tagsDesign, tagsConstruction } = response.data[0];
                        setSelectedTags({
                            design: tagsDesign ? tagsDesign.split(',') : [],
                            construction: tagsConstruction ? tagsConstruction.split(',') : [],
                        });
                    }
                } else {
                    setHasNoPackage(true);
                }
            })
        } catch (err) {
            console.log('found error ', err);
        }
    }

    const uploadVideos = async (file, bucketName, packageName) => {
        try {
            setScopeOfWorkVideoThumbnail(NoCoverImage);
            setUploadStarted(true); // Assuming this is to indicate the start of the upload
            const fileName = cleanMediaUrl(file.name);
            const key = `${packageName}/${scopeOfWorkName}/${fileName}`;

            await uploadWithSignedUrl(file, bucketName, key, (myProgress) => {
                setFileProgressScopeOfWork(myProgress);
            });
        } catch (error) {
            setUploadStarted(false);
            console.error('Upload error:', error);
        } finally {
            const fileNameArray = cleanMediaUrl(file.name).split('.');
            const newFileName = `${fileNameArray[0]}.mp4`;
            const newThumbNailFileName = `${fileNameArray[0]}.jpg`;
            setShowProcessing(true);
            setHasFilesToBeUploaded(false);
            setTimeout(() => {
                setScopeOfWorkVideo(`${s3BucketPrefixVideo}/${packageName}/${scopeOfWorkName}/${newFileName}`);
                setScopeOfWorkVideoThumbnail(`${s3ThumbnailBucketPrefix}/${thumbNailPrefix}-${packageName}/${scopeOfWorkName}/${newThumbNailFileName}`);
                setCurrentCoverImageIndex(0);
                setSelectedFileCoverImage([]);
                setSelectedFileScopeOfWork([]);
                setShowProcessing(false);
                setUploadStarted(false);
            }, process.env.REACT_APP_TIEMOUT);
        }
    }

    const handleUpload = async (type) => {
        try {
            if (hasFilesToBeUploaded) {
                if (!showWrongImageFormat) {
                    setFileProgressScopeOfWork(0);
                    setCountScopeOfWorkVideo(0);
                    setShowNoFilesToBeUpdated(false);
                    setUploadStarted(true);
                    switch (type) {
                        case 'coverImage':
                            try {
                                let myCounter = 0;
                                if (selectedFileCoverImage.length > 0) {
                                    const coverImageArray = [...selectedFileCoverImage];
                                    let coverArray = [];
                                    if (tempCoverImageCounter === 0) {
                                        coverArray = coverImageArray.splice(0, numberOfFilesToBeUploadedAtTime);
                                    } else {
                                        coverArray = coverImageArray.splice(tempCoverImageCounter, numberOfFilesToBeUploadedAtTime);
                                    }
                                    coverArray.map(async (file) => {
                                        try {
                                            coverImageFileName = cleanMediaUrl(file.name);
                                            const justFileName = cleanMediaUrl(file.name);
                                            coverImageFileName = `${packageName}/${folderName}/${coverImageFileName}`;
                                            await uploadWithSignedUrl(file, fazzadProjectBucket, coverImageFileName);
                                            setCoverImage(cleanMediaUrl(file.name), packageName);

                                            myCounter++;
                                            tempCoverImageCounter++;
                                            setCurrentCoverImageIndex(tempCoverImageCounter);
                                            setCoverImageSource(`${s3BucketPrefix}/${packages[0].packageName}/${folderName}/${justFileName}`);
                                            setCoverImageName(justFileName);
                                            handleUpload('scopeofwork');
                                        } catch (err) {
                                            console.log('error = ', err)
                                        }
                                    });
                                } else {
                                    handleUpload('scopeofwork');
                                }
                            } catch (e) {
                                console.error(`Uploading Cover Image file error: ${e}`);
                            }
                            break;
                        case 'scopeofwork':
                            try {
                                if (selectedFileScopeOfWork.length > 0) {
                                    setHasFilesToBeUploaded(true);
                                    deleteThumbnail(packageName, process.env.REACT_APP_PROJECT_VIDEO_THUMBNAIL);
                                } else {
                                    setShowProcessing(false);
                                    setUploadStarted(false);
                                    setSelectedFileCoverImage([]);
                                    setSelectedFileScopeOfWork([]);
                                    setHasFilesToBeUploaded(false);
                                    setCurrentCoverImageIndex(0);
                                }
                            } catch (e) {
                                console.error(`Uploading Scope of Work Videos file error: ${e}`);
                            }
                            break;
                        default:
                            break;
                    }
                }
            } else {
                setShowNoFilesToBeUpdated(true);
                setShowNoFilesToBeUpdated(true);
                setUploadStarted(false);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getUserProfile = (userId) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getUserProfile`, { userId: userId }).then((response) => {
            if (response.data.length > 0) {
                if (parseInt(response.data[0].userType) === parseInt(userType) && parseInt(response.data[0].userId) === parseInt(userId)) {
                    getFiles();
                    setHasEmail(true);
                    setHasAddress(true);
                    setHasCity(true);
                    setHasZip(true);
                    setHasScopeOfWork(true);
                    setHasStartDate(true);
                    getPackageEdit(packageName);
                } else {
                    setShowNotAllowed(true);
                }
            } else {
                setShowNotAllowed(true);
            }
        }).catch(function (error) {
            console.log('tags error = ', error);
        })
    }

    const setCoverImage = (fileName, pckName) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/updateCoverImage`, {
            packageName: pckName,
            coverImageUrl: fileName
        }).then((response) => {
            return;
        });
    }

    const setTags = (e, type) => {
        const value = e.target.value;
        const isChecked = e.target.checked;

        setSelectedTags(prevTags => {
            // Create a copy of the current tag array
            const updatedTags = [...prevTags[type]];

            if (isChecked) {
                // Add the value if it's checked and not already present
                if (!updatedTags.includes(value)) {
                    updatedTags.push(value);
                }
            } else {
                // Remove the value if it's unchecked
                const indexToRemove = updatedTags.indexOf(value);
                if (indexToRemove !== -1) {
                    updatedTags.splice(indexToRemove, 1);
                }
            }

            // Return the updated state
            return {
                ...prevTags,
                [type]: updatedTags,
            };
        });
    };

    const checkForm = () => {
        let url = 'www.fazzad.com';
        if (dropBoxLink.trim().length > 0) {
            let dropBoxLinkTest = dropBoxLink;
            if (!/^https?:\/\//i.test(dropBoxLinkTest)) {
                dropBoxLinkTest = 'https://' + dropBoxLinkTest;
            }
            url = new URL(dropBoxLinkTest);
            setDropBoxLink(dropBoxLinkTest);
        }
        let isValid = true;
        if (
            city.trim().length === 0 ||
            startDate.trim().length === 0 ||
            zip.trim().length === 0 ||
            zip.trim().length < 5 ||
            !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip)
        ) {
            isValid = false;
        }

        if (typeof address !== 'undefined' && typeof address !== null && typeof address !== 'boolean') {
            if (addressRef.current.value.trim().length === 0) {
                isValid = false;
            }
        }
        return isValid;
    }

    const changeHandlerScopeOfWork = (event) => {
        event.preventDefault();

        const chosenFile = event.target.files[0].name;
        if (!videoExtensionsRegex.test(chosenFile)) {
            setIsValidScopeVideo(false);
        } else {
            setHasFilesToBeUploaded(true);
            setSelectedFileScopeOfWork(event.target.files);
            setIsValidScopeVideo(true);
        }
        scopeOfWorkRef.current.blur();
    }

    const handleBackButton = () => {
        setIsPackageUpdated(false);
        setUploadMoreFiles(false);
        setHasNoPackage(false);
        const urlWithoutQueryParams = window.location.protocol + "//" + window.location.host + window.location.pathname;

        // Update the URL without reloading the page
        window.history.pushState({ path: urlWithoutQueryParams }, '', urlWithoutQueryParams);

        // Reload the page
        window.location.reload();
    }

    const sendPropertyOwnerEmail = (email, packageName, address, address2, city, state, zip) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/sendHomeOwnerUpdatedPackageEmail`, {
            email: email,
            packageName: packageName,
            address: address,
            address2: address2,
            city: city,
            state: state,
            zip: zip
        }).then((response) => {
            return;
        });
    }

    const handleProjectTypeChange = (event) => {
        setProjectType(event.target.value);
    };

    const editPackage = () => {

        if (formValidator.checkAddress(address)) { setHasAddress(true); } else { setHasAddress(false); }
        if (formValidator.checkCity(city)) { setHasCity(true); } else { setHasCity(false); }
        if (formValidator.checkZip(zip)) { setHasZip(true); } else { setHasZip(false); }
        if (formValidator.checkState(state)) { setHasState(true); } else { setHasState(false); }
        if (formValidator.checkStartDate(startDate)) { setHasStartDate(true); } else { setHasStartDate(false); }

        if (checkForm()) {
            const params = {
                packageName: packageName,
                tagsDesign: selectedTags.design.toString(),
                tagsConstruction: selectedTags.construction.toString(),
                scopeOfWork: scopeOfWork,
                homeOwnerEmail: homeOwnerEmail,
                packageAddress: address,
                packageAddress2: address2,
                packageCity: city,
                packageState: state,
                packageZip: zip,
                startDate: startDate,
                dropBoxLink: dropBoxLink,
                projectType: projectType,
                packagePrivate: packagePrivate,
                userId: userId
            };
            Axios.post(`${process.env.REACT_APP_LOCALHOST}/editCurrentPackage`, params).then((response) => {
                if (response.data.changedRows === 1 || response.data.affectedRows === 1) {
                    // setIsPackageUpdated(true);
                    navigate(`/editpackagefolderspictures/${packageName}`);
                    setUploadMoreFiles(false);
                    // sendPropertyOwnerEmail(homeOwnerEmail, packageName, address, address2, city, state, zip);
                } else {
                    setIsPackageUpdated(false);
                    <div className="fz-alert alert-info">
                        <i className="icon-info"></i>
                        <strong>Info</strong>
                        <p>We couldn't update your package. Please try it again.</p>
                    </div>
                }
            }).catch(function (error) {
                console.log(error);
                setIsPackageUpdated(false);
            })
        }
    }

    return (
        <div id="fazzad-site-container">
            <Header />
            {hasNoPackage ?
                <div>
                    <main id="fazzad-main">
                        <div id="app-container">
                            <BreadCrumbs
                                header1={'Home'}
                                isLink1Clickable={true}
                                link1={'survey'}
                                header2={'Package Details'}
                                link2={``}
                                isLink2Clickable={false}
                                isLink2Navigation={false}
                                header3={''}
                                link3={''}
                            />
                            <AlertMessage
                                messageHeader={"No packages found."}
                                messageBody={"The package you are trying to edit does not exist or doesn't belong to you."}
                                startDate={null}
                                setShowWarningContainerMessage={''}
                                messageType={"informational"}
                                showButton={false}
                            />
                        </div>
                    </main>
                </div>
                :
                !isPackageUpdated && !uploadMoreFiles && !hasNoPackage ?
                    <main id="fazzad-main">
                        <div id="app-container">
                            <BreadCrumbs
                                header1={'Home'}
                                isLink1Clickable={true}
                                link1={'survey'}
                                header2={'Package Details'}
                                link2={``}
                                isLink2Clickable={false}
                                isLink2Navigation={false}
                                header3={''}
                                link3={''}
                            />
                            <PageTitleWithSteps
                                currentStep1={true}
                                currentStep2={false}
                                header={'Edit package'}
                                subHeader={''}
                                step1Header={'Step One'}
                                step1SubHeader={'Fill out the Survey Package information.'}
                                step2Header={'Step Two'}
                                step2SubHeader={'Upload files related to the Survey Package.'}
                                completedStep1={true}
                                completedStep2={false}
                                isInactive={false}
                                isNewPackage={false}
                            />
                            {showWarningContainerMessage ?
                                <>
                                    <AlertMessage
                                        messageHeader={'Editing existing package.'}
                                        messageBody={'This package was created on '}
                                        messageBodyContinuation={' Any saved changes will update the existing package information.'}
                                        startDate={startDate}
                                        onButtonClick={handleAlertButtonClick}
                                        messageType={'warning'}
                                    />
                                </>
                                : ''
                            }
                            <div className="form-container" id="create-package">
                                <form action="">
                                    <section className="form-details">
                                        <h2 className="h4">Details</h2>
                                        <div className="form-row col-2">
                                            <div className={`form-field text-input ${!hasStartDate ? 'error' : ''}`}>
                                                <label htmlFor="start-date">Start Date</label>
                                                <input
                                                    type="date"
                                                    id="start-date"
                                                    value={startDate}
                                                    onFocus={(e) => {
                                                        setHasStartDate(true);
                                                    }}
                                                    onChange={(e) => {
                                                        setStartDate(e.target.value);
                                                    }}
                                                />
                                                {!hasStartDate ?
                                                    <p className="validation-message">Enter a valid date</p>
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                        <MailingAddressForm
                                            setAddress={setAddress}
                                            addressRef={addressRef}
                                            hasAddress={hasAddress}
                                            setHasAddress={setAddress}
                                            setAddress2={setAddress2}
                                            address2={address2}
                                            city={city}
                                            setCity={setCity}
                                            hasCity={hasCity}
                                            setHasCity={setHasCity}
                                            state={state}
                                            setState={setState}
                                            hasState={hasState}
                                            setHasState={setHasState}
                                            zip={zip}
                                            setHasZip={setHasZip}
                                            hasZip={hasZip}
                                            setZip={setZip}
                                            formValidator={formValidator}
                                            showHeader={false}
                                        />
                                        <div className={`form-field text-area scope-text-area ${!hasScopeOfWork ? 'error' : ''}`}>
                                            <label htmlFor="scope-of-work">Scope of Work (optional)</label>
                                            <div className="input-container">
                                                <textarea
                                                    type="text"
                                                    id="scope-of-work"
                                                    onBlur={(e) => {
                                                        formValidator.clearEmojis(e.target.value);
                                                    }}
                                                    onFocus={(e) => {
                                                        setHasScopeOfWork(true);
                                                    }}
                                                    onChange={(e) => {
                                                        {
                                                            handleTextChange(e)
                                                        }
                                                        ;
                                                    }}
                                                    value={scopeOfWork}
                                                >
                                                    {scopeOfWork}
                                                </textarea>
                                            </div>
                                        </div>
                                        <div className="form-row col-2">
                                            <div className="form-field text-input">
                                                <label htmlFor="dropbox-link">Dropbox Link (optional)</label>
                                                <input
                                                    type="text"
                                                    value={dropBoxLink}
                                                    id="dropbox-link"
                                                    onBlur={(e) => {
                                                        formValidator.clearEmojis(e.target.value);
                                                    }}
                                                    onChange={(e) => {
                                                        setDropBoxLink(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </section>

                                    <section className='form-details'>
                                        <h2 className="h4">Project type</h2>
                                        <div className="form-row col-2">
                                            <div className="form-radio">
                                                <div className="form-field radio">
                                                    <input
                                                        type="radio"
                                                        name="projectType"
                                                        id="residentialRadio" // Unique id
                                                        value="2" // Value representing this option
                                                        checked={parseInt(projectType) === 2} // Check against value
                                                        onChange={handleProjectTypeChange} // Call the function
                                                    />
                                                    <label htmlFor="residentialRadio">Residential</label>
                                                    <input
                                                        type="radio"
                                                        name="projectType"
                                                        id="commercialRadio" // Unique id
                                                        value="1" // Value representing this option
                                                        checked={parseInt(projectType) === 1} // Check against value
                                                        onChange={handleProjectTypeChange} // Call the function
                                                    />
                                                    <label htmlFor="commercialRadio">Commercial</label>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section className='form-details'>
                                        <h2 className="h4">Visibility</h2>
                                        <div className="form-row col-2">
                                            <div className="form-radio">
                                                <div className="form-field radio">
                                                    <input
                                                        type="radio"
                                                        name="packageVisibility"
                                                        id="publicRadio"
                                                        value="public"
                                                        checked={!packagePrivate}
                                                        onChange={() => setPackagePrivate(false)}
                                                    />
                                                    <label htmlFor="publicRadio">Public View</label>
                                                    <input
                                                        type="radio"
                                                        name="packageVisibility"
                                                        id="privateRadio"
                                                        value="private"
                                                        checked={packagePrivate}
                                                        onChange={() => setPackagePrivate(true)}
                                                    />
                                                    <label htmlFor="privateRadio">Private View</label>
                                                </div>
                                            </div>
                                        </div>
                                        {packagePrivate && (
                                            <p>
                                                <small>Only Property Owner will be able to see your package.</small>
                                            </p>
                                        )}
                                    </section>

                                    <section className="form-tags">
                                        <JobTags
                                            isTagSelected={isTagSelected}
                                            setTags={setTags}
                                        />
                                    </section>
                                    <nav id="step-controls">
                                        <div className="button-group">
                                            <button type="button" className="button-m button-tertiary" onClick={() => {
                                                navigate(`/survey`)
                                            }}><span className="text">Exit</span></button>
                                            <button
                                                type="button"
                                                className="button-m button-primary"
                                                onClick={() => {
                                                    editPackage();
                                                }}
                                            >
                                                <span className="text">Update & Continue</span>
                                            </button>
                                        </div>
                                    </nav>
                                </form>
                            </div>
                        </div>
                    </main>
                    :
                    <>
                        <main id="fazzad-main">
                            <div id="app-container">
                                <BreadCrumbs
                                    header1={'Home'}
                                    link1={'survey'}
                                    header2={'Package Details'}
                                    link2={'editpackage'}
                                    isLink2Clickable={true}
                                    isLink2Navigation={false}
                                    header3={'Upload Files'}
                                    isLink1Clickable={true}
                                />
                                <PageTitleWithSteps
                                    currentStep1={false}
                                    currentStep2={true}
                                    header={'Edit Plan & Picture'}
                                    subHeader={''}
                                    step1Header={'Step One'}
                                    step1SubHeader={'Fill out the Survey Package information.'}
                                    step2Header={'Step Two'}
                                    step2SubHeader={'Upload files related to the Survey Package.'}
                                    completedStep1={true}
                                    completedStep2={true}
                                    isInactive={false}
                                    isNewPackage={false}
                                />

                                <div className="form-container" id="edit-package">
                                    <form action="">
                                        <section className="form-pdfs outlined collapsed">
                                            <h2 className="h4">Cover Picture <span
                                                className="count">({coverImageInPackage} file{coverImageInPackage > 1 ? 's' : ''})</span>
                                            </h2>
                                            <div className="button-group">
                                                <button
                                                    type="button"
                                                    className="button-s button-tertiary"
                                                    onClick={() => navigate(`/editpackagefolderspictures/${packageName}/cover-image`)}
                                                >
                                                    {getSvg('edit')}
                                                    <span className="text">Edit file</span>
                                                </button>
                                            </div>
                                        </section>
                                        <section className="form-pdfs outlined collapsed">
                                            <h2 className="h4">Scope of Work <span className="count">({scopeOfWorkInPackage} file{scopeOfWorkInPackage > 1 ? 's' : ''})</span></h2>
                                            <div className="button-group">
                                                <button
                                                    type="button"
                                                    className="button-s button-tertiary"
                                                    onClick={() => navigate(`/editpackagefolderspictures/${packageName}/scope-of-work`)}
                                                >
                                                    {getSvg('edit')}
                                                    <span className="text">Edit file</span>
                                                </button>
                                            </div>
                                        </section>
                                        <section className="form-pdfs outlined collapsed">
                                            <h2 className="h4">PDFs <span className="count">({pdfsInPackage} file{pdfsInPackage > 1 ? 's' : ''})</span></h2>
                                            <div className="button-group">
                                                <button
                                                    type="button"
                                                    className="button-s button-tertiary"
                                                    onClick={() => navigate(`/editpackagefolderspictures/${packageName}/pdf-files`)}
                                                >
                                                    {getSvg('edit')}
                                                    <span className="text">Edit files</span>
                                                </button>
                                            </div>
                                        </section>
                                        <section className="form-pictures outlined collapsed">
                                            <h2 className="h4">Still Pictures <span className="count">({stillPicturesInPackage} file{stillPicturesInPackage > 1 ? 's' : ''})</span></h2>
                                            <div className="button-group">
                                                <button
                                                    type="button"
                                                    className="button-s button-tertiary"
                                                    onClick={() => navigate(`/editpackagefolderspictures/${packageName}/still-pictures`)}
                                                >
                                                    {getSvg('edit')}
                                                    <span className="text">Edit files</span>
                                                </button>
                                            </div>
                                        </section>
                                        <section className="form-360-pictures outlined collapsed">
                                            <h2 className="h4">360º Pictures <span className="count">({threeSixtyPicturesInPackage} file{threeSixtyPicturesInPackage > 1 ? 's' : ''})</span></h2>
                                            <div className="button-group">
                                                <button
                                                    type="button"
                                                    className="button-s button-tertiary"
                                                    onClick={() => navigate(`/editpackagefolderspictures/${packageName}/three-sixty-pictures`)}
                                                >
                                                    {getSvg('edit')}
                                                    <span className="text">Edit files</span>
                                                </button>
                                            </div>
                                        </section>
                                        <section className="form-drone-pictures outlined collapsed">
                                            <h2 className="h4">Drone Pictures <span className="count">({dronePicturesInPackage} file{dronePicturesInPackage > 1 ? 's' : ''})</span></h2>
                                            <div className="button-group">
                                                <button
                                                    type="button"
                                                    className="button-s button-tertiary"
                                                    onClick={() => navigate(`/editpackagefolderspictures/${packageName}/drone-pictures`)}
                                                >
                                                    {getSvg('edit')}
                                                    <span className="text">Edit files</span>
                                                </button>
                                            </div>
                                        </section>
                                        <section className="form-cad outlined collapsed">
                                            <h2 className="h4">CAD <span className="count">({dwgPicturesInPackage} file{dwgPicturesInPackage > 1 ? 's' : ''})</span></h2>
                                            <div className="button-group">
                                                <button
                                                    type="button"
                                                    className="button-s button-tertiary"
                                                    onClick={() => navigate(`/editpackagefolderspictures/${packageName}/dwg-files`)}
                                                >
                                                    {getSvg('edit')}
                                                    <span className="text">Edit file</span>
                                                </button>
                                            </div>
                                        </section>
                                        <nav id="step-controls">
                                            <div className="button-group">
                                                <button
                                                    type="button"
                                                    className="button-m button-tertiary"
                                                    onClick={() => { handleBackButton() }}
                                                >
                                                    <span className="text">Back</span>
                                                </button>
                                                {/* <button
                                        type="button"
                                        className="button-m button-primary"
                                        onClick={() => handleUpload('coverImage')}
                                    >
                                        <span className="text">Save</span>
                                    </button> */}
                                            </div>
                                        </nav>
                                    </form>
                                </div>
                            </div>
                        </main>

                        {uploadStarted ?
                            <div className="modal-container">
                                <div className="modal">
                                    {!showProcessing ?
                                        <>
                                            <div className="modal__header">
                                                <h2 className="modal-title h4">File Upload Progress</h2>
                                            </div>
                                            <div className="modal__content">
                                                <div className="alert-container">
                                                    <ModalAlertMessage
                                                        messageHeader={process.env.REACT_APP_PROCESSING_MESSAGE_WAIT}
                                                        messageType={'informational'}
                                                    />
                                                </div>
                                                <div className="file-uploads">
                                                    <div className="fz-block-sm"></div>
                                                    {selectedFileCoverImage.length > 0 ?
                                                        <section>
                                                            <h5>Cover Picture</h5>
                                                            {currentCoverImageIndex !== 0 && currentCoverImageIndex < selectedFileCoverImage.length && selectedFileCoverImage.length > 0 ?
                                                                currentCoverImageIndex > 0 ?
                                                                    <>
                                                                        <div className="message">
                                                                            <p>Uploading picture <span className="loader loader--s"></span></p>

                                                                        </div>
                                                                        <div className="message">
                                                                            <progress className='progress-bar' value={currentCoverImageIndex} max={selectedFileCoverImage.length}></progress> {calculatePercentage(selectedFileCoverImage.length, currentCoverImageIndex)} %
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    ''
                                                                : <div className='message'>
                                                                    <p>Cover Picture {selectedFileCoverImage.length > 1 ? 's have' : 'has'} been uploaded!</p>
                                                                    <p>Picture will be updated soon.</p>
                                                                    {getSvg('green-checkmark')}
                                                                </div>
                                                            }
                                                            {/* {selectedFileCoverImage.length > 0 && currentCoverImageIndex === 0 ? <div className="message">Preparing cover picture{selectedFileCoverImage.length > 1 ? 's' : ''} to be uploaded  <span className="loader loader--s"></span></div> : ''} */}
                                                        </section>
                                                        : ''
                                                    }
                                                    {selectedFileScopeOfWork.length > 0 ?
                                                        <section>
                                                            <h5>Scope of Work</h5>
                                                            {fileProgressScopeOfWork > 0 && countScopeOfWorkVideo < 1 ?
                                                                <ProgressBar
                                                                    fileProgress={fileProgressScopeOfWork}
                                                                    fileType={'video'}
                                                                />
                                                                : ''
                                                            }
                                                            {selectedFileScopeOfWork.length > 0 && fileProgressScopeOfWork === 0 ?
                                                                <div className="message">
                                                                    Preparing video{selectedFileScopeOfWork.length > 1 ? 's' : ''} to be uploaded
                                                                    <span className="loader loader--s"></span>
                                                                </div>
                                                                : ''
                                                            }
                                                        </section>
                                                        : ''
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="modal__header">
                                                <h2 className="modal-title h4">Package successfully updated.</h2>
                                            </div>
                                            <div className="modal__content">
                                                <div className='fz-block-sm'>
                                                    <AlertMessage
                                                        messageHeader={processingMessage}
                                                        messageBody={""}
                                                        startDate={null}
                                                        setShowWarningContainerMessage={''}
                                                        messageType={"informational"}
                                                        showButton={false}
                                                        showLoader={true}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            : ''
                        }
                        {showNoFilesToBeUpdated ?
                            <div id="modal-2" className="modal-container">
                                <div className="modal">
                                    <div className="modal__header">
                                        <h2 className="modal-title h4 success">Package updated.</h2>
                                        <button
                                            type="button"
                                            className="button-tertiary button-s button-icon modal-close"
                                            onClick={() => setShowNoFilesToBeUpdated(false)}
                                        >
                                            {getSvg('close')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            : ''
                        }
                    </>
            }
            {showNotAllowed ?
                <ModalInfo
                    messageHeader={'You are not allowed to see this page.'}
                    messageBody={'This page is just for registered surveyors.'}
                    messageType={'error'}
                />
                : ''
            }
        </div>
    )
};
