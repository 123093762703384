import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import AlertMessage from './AlertMessage';
import Axios from 'axios';
import checkUserProfile from '../../utils/checkUserProfile';
import getSvg from '../../utils/getSvg';
import Header from './Header'
import {copyToClipBoard} from '../../utils/copyToClipBoard';
import {LinkGeneratorHelpers} from '../../utils/linkGeneratorHelpers';


export default function ShareContacts() {
    const navigate = useNavigate();
    const {userId, type, packageName} = useParams();
    const [myContacts, setMyContacts] = useState(['']);
    const [buttonTitle, setButtonTitle] = useState('Send Invitations');
    const [disableButton, setDisableButton] = useState(false);
    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const [showAlertMessageAddEmail, setShowAlertMessageAddEmail] = useState(false);
    const [showDuplicatedEmailsMsg, setShowDuplicatedEmailsMsg] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showNoContacts, setShowNoContacts] = useState(false);
    const [sendingInvitation, setSendingInvitation] = useState(false);
    const [showNotAllowed, setShowNotAllowed] = useState(false);
    const [showWrongEmails, setShowWrongEmails] = useState(false);
    const [wrongEmails, setWrongEmails] = useState([]);
    const [emailsSent, setEmailsSent] = useState([]);
    const [showCopySuccess, setShowCopySuccess] = useState(false);
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    const userType = parseInt(sessionStorage.getItem('userType'));
    const userTypeToBeChecked = parseInt(process.env.REACT_APP_SURVEYOR_USER_TYPE_TO_BE_CHECKED);
    sessionStorage.setItem('userType', userTypeToBeChecked);
    const handleGoToLogin = () => navigate('/login');
    let message = "Invite surveyors";
    if (Number(type) === 500002) {
        message = "Invite Pros & Vendors to your network";
    }
    useEffect(() => {
        checkLogin()
    }, []);
    const checkLogin = () => {
        if (isLoggedIn === 'true') {
            try {
                getUserProfile(userId, userType, userTypeToBeChecked);
            } catch (err) {
                console.log('error trying to get the package id = ', err);
            }
            ;
        } else {
            handleGoToLogin();
        }
    }

    const getUserProfile = async (userId, userType, userTypeToBeChecked) => {
        const {isGoodToGo} = await checkUserProfile(userId, userType, userTypeToBeChecked);
        if (!isGoodToGo) {
            setShowNotAllowed(true);
        }
    }

    const addEmailField = () => {
        setWrongEmails([]);
        setShowAlertMessageAddEmail(false);
        setShowSuccessMessage(false);
        let hasBadEmail = false;
        myContacts.forEach((email) => {
            if (email.trim().length === 0) {
                hasBadEmail = true;
            }
        });
        if (hasBadEmail) {
            setShowNoContacts(true);
            return;
        }
        myContacts.forEach((email) => {
            const matchEmail = email.match(/^[\w.-]+@[\w.-]+\.[\w]{2,}$/i);
            if (Array.isArray(matchEmail)) {
                setMyContacts([...myContacts, '']); // Add a new input field only if the email is valid
            } else {
                setWrongEmails([...wrongEmails, email]);
                setShowAlertMessageAddEmail(true);
            }
        });
    };

    // Function to handle changes in email input fields
    const handleEmailChange = (index, value) => {
        setDisableButton(false);
        const updatedEmails = [...myContacts];
        updatedEmails[index] = value;
        setMyContacts(updatedEmails);
    };

    const shareContacts = (type) => {
        if (!showDuplicatedEmailsMsg) {
            setShowSuccessMessage(false);
            setShowAlertMessage(false);
            setShowAlertMessageAddEmail(false);
            setShowNoContacts(false);
            if (!sendingInvitation && userId !== '') {
                const goodEmails = [];
                myContacts.forEach((email) => {
                    if (email.trim().length > 0) {
                        const matchEmail = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                        if (Array.isArray(matchEmail) && matchEmail.length > 0) {
                            goodEmails.push(email);
                        }
                    }
                });

                if (goodEmails.length > 0 && userId !== '') {
                    setDisableButton(true);
                    setSendingInvitation(true);
                    setButtonTitle('Sending Invitations...');
                    Axios.post(`${process.env.REACT_APP_LOCALHOST}/shareContacts`, {
                        userId: userId,
                        contacts: goodEmails,
                        userType: type,
                        packageName: packageName
                    }).then((response) => {
                        if (response.data?.messages?.length > 0) {
                            const containsError = response.data.messages.some(message => message.includes('error'));
                            setDisableButton(false);
                            if (containsError) {
                                console.log('Error sending emails');
                            } else {
                                setShowSuccessMessage(true);
                            }
                        }
                        setButtonTitle('Send Invitations');
                        setShowSuccessMessage(true);
                        setSendingInvitation(false);
                        setMyContacts(['']);
                    }).catch(function (error) {
                        setDisableButton(false);
                        setShowNoContacts(false);
                        setSendingInvitation(false);
                        setButtonTitle('Send Invitations');
                        setShowSuccessMessage(false);
                    })
                } else {
                    setShowAlertMessage(true);
                }
                setEmailsSent(goodEmails);
            } else {
                setDisableButton(false);
                setSendingInvitation(false);
                setShowAlertMessage(true);
            }
        }
    }

    const checkForDuplicates = (email, index) => {
        const updatedEmails = [...myContacts];
        updatedEmails[index] = email;
        setMyContacts(updatedEmails);
        const filteredEmails = myContacts.filter((item) => item !== '');
        const hasDuplicates = (new Set(filteredEmails)).size !== filteredEmails.length;
        if (hasDuplicates) {
            setDisableButton(true);
            setShowDuplicatedEmailsMsg(true);
        } else {
            setDisableButton(false);
            setShowDuplicatedEmailsMsg(false);
        }
    }

    const closeDuplicatedMsg = () => {
        setShowDuplicatedEmailsMsg(false);
        setShowAlertMessage(false);
        setShowNoContacts(false);
        setShowAlertMessageAddEmail(false);
        setShowSuccessMessage(false);
    };

    const getInviteLink = () => {
        if (Number(type) === 500001) {
            // For Surveyor
            return LinkGeneratorHelpers.generateSignupLink(userId, '');
        } else {
            // For Pro
            return LinkGeneratorHelpers.generateShowProDetailsLink(packageName, userId);
        }
    };

    const handleCopyToClipboard = () => {
        const link = getInviteLink();
        copyToClipBoard(link);
        setShowCopySuccess(true);
        setTimeout(() => {
            setShowCopySuccess(false);
        }, 3000);
    };

    return (
        <div id="fazzad-site-container">
            <Header/>
            {showNotAllowed ?
                <div className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4 warning">You are not allowed to see this page.</h2>
                            <button type="button" className="button-tertiary button-s button-icon modal-close"
                                    onClick={() => navigate('/')}>
                                {getSvg('close')}
                            </button>
                        </div>
                    </div>
                </div>
                : ''
            }
            <main id="fazzad-main">
                <div id="app-container">
                    <nav className="breadcrumbs-container" aria-label="Breadcrumbs">
                        <ol>
                            <li>
                                <a href="/" onClick={(e) => {
                                    e.preventDefault();
                                    navigate(`/survey`)
                                }}>Home</a>
                                <span className="separator">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                            </li>
                            <li aria-current="page">
                                <span>
                                    {Number(type) === 500001 ? "Invite Surveyors" : "Invite Pros & Vendors"}
                                </span>
                            </li>
                        </ol>
                    </nav>
                    {showSuccessMessage ?
                        <div className="alert-container">
                            <div className="alert alert--success">
                                <div className="alert__content">
                                    <p className="alert__heading">Invites sent successfully</p>
                                    <p className="alert__message">Feel free to invite more contacts or <a href="/"
                                                                                                          onClick={(e) => {
                                                                                                              e.preventDefault();
                                                                                                              navigate(`/survey`)
                                                                                                          }}>return to
                                        your home feed</a>.</p>
                                </div>
                                <button type="button" className="button-tertiary button-s button-icon alert__close"
                                        onClick={() => setShowSuccessMessage(false)}>
                                    {getSvg('close')}
                                </button>
                            </div>
                        </div>
                        : ''
                    }
                    {showCopySuccess ?
                        <div className="alert-container">
                            <div className="alert alert--success">
                                <div className="alert__content">
                                    <p className="alert__heading">Link copied to clipboard!</p>
                                    <p className="alert__message">You can now paste and share this link.</p>
                                </div>
                                <button type="button" className="button-tertiary button-s button-icon alert__close"
                                        onClick={() => setShowCopySuccess(false)}>
                                    {getSvg('close')}
                                </button>
                            </div>
                        </div>
                        : ''
                    }
                    <div id="page-title">
                        <div>
                            <h1 className="h3">{message}</h1>
                        </div>
                        {type === '500001' ?

                            <>
                                <video controls width='100%'
                                       poster={'https://fazzad-courses.s3.us-west-1.amazonaws.com/tutorials/thumbnails/photo-00-surveyor-invite.png'}
                                       height='auto'>
                                    <source
                                        src={'https://fazzad-courses.s3.us-west-1.amazonaws.com/tutorials/Surveyor+-+Invite.mp4'}
                                        type='video/mp4'/>
                                    <p>Your browser does not support the video element.</p>
                                </video>
                            </>

                            :

                            <>
                                <video controls width='100%'
                                       poster={'https://fazzad-courses.s3.us-west-1.amazonaws.com/tutorials/thumbnails/photo-00-Surveyor%2B-%2BInvite%2BPros%2Band%2BVendors.png'}
                                       height='auto'>
                                    <source
                                        src={'https://fazzad-courses.s3.us-west-1.amazonaws.com/tutorials/Surveyor+-+Invite+Pros+and+Vendors.mp4'}
                                        type='video/mp4'/>
                                    <p>Your browser does not support the video element.</p>
                                </video>
                            </>
                        }
                    </div>
                    <div className="form-container" id="invite-network">
                        {showAlertMessage && (
                            <AlertMessage
                                messageHeader={"No email or invalid address entered."}
                                messageType={"error"}
                                onButtonClick={closeDuplicatedMsg}
                            />
                        )}
                        {showNoContacts ?
                            <AlertMessage
                                messageHeader={"No email or invalid address entered."}
                                messageType={"error"}
                                onButtonClick={closeDuplicatedMsg}
                            />
                            : ''
                        }
                        {showAlertMessageAddEmail && (
                            <AlertMessage
                                messageHeader={"No email or invalid address entered."}
                                messageBody={""}
                                messageType={"error"}
                                onButtonClick={closeDuplicatedMsg}
                            />
                        )}
                        {/*<form action="">*/}
                        {/*    <section className="emails">*/}
                        {/*        {myContacts.map((email, index) => (*/}
                        {/*            <div className="form-field text-input" key={index}>*/}
                        {/*                <label htmlFor={`invite-email-address-${index}`}>E-mail Address</label>*/}
                        {/*                <input*/}
                        {/*                    type="email"*/}
                        {/*                    id={`invite-email-address-${index}`}*/}
                        {/*                    value={email}*/}
                        {/*                    onChange={(e) => {*/}
                        {/*                        handleEmailChange(index, e.target.value);*/}
                        {/*                        setShowWrongEmails(false);*/}
                        {/*                        setShowAlertMessageAddEmail(false);*/}
                        {/*                        setShowAlertMessage(false);*/}
                        {/*                        setShowNoContacts(false);*/}
                        {/*                        setShowSuccessMessage(false);*/}
                        {/*                        setShowNotAllowed(false);*/}
                        {/*                    }}*/}
                        {/*                    onBlur={(e) => {*/}
                        {/*                        checkForDuplicates(e.target.value, index)*/}
                        {/*                    }} // Check for duplicates when the user leaves the input field*/}
                        {/*                />*/}
                        {/*            </div>*/}
                        {/*        ))}*/}
                        {/*        {showDuplicatedEmailsMsg ?*/}
                        {/*            <AlertMessage*/}
                        {/*                messageHeader={"Duplicate emails are not allowed."}*/}
                        {/*                messageBody={""}*/}
                        {/*                messageType={"error"}*/}
                        {/*                onButtonClick={closeDuplicatedMsg}*/}
                        {/*            />*/}
                        {/*            : ''*/}
                        {/*        }*/}
                        {/*        <div className="button-group">*/}
                        {/*            <button type="button" className="button-s button-tertiary" id="button-add-email"*/}
                        {/*                    onClick={addEmailField}>*/}
                        {/*                <span className="icon">*/}
                        {/*                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"*/}
                        {/*                         xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                        <path d="M12 5V19M5 12H19" stroke="currentColor" strokeWidth="2"*/}
                        {/*                              strokeLinecap="round" strokeLinejoin="round"/>*/}
                        {/*                    </svg>*/}
                        {/*                </span>*/}
                        {/*                <span className="text">Add Another Email</span>*/}
                        {/*            </button>*/}
                        {/*        </div>*/}
                        {/*    </section>*/}
                        {/*</form>*/}


                        {Number(type) === 500002 ?
                            <p>
                                Click this button to copy your unique invitation link. Share it with Pros & Vendors to
                                invite them to this specific Survey Package and your network on Fazzad.
                            </p>
                            :
                            <p>
                                Click this button to copy your unique invitation link. Share it with surveyors to invite
                                them to join Fazzad.
                            </p>
                        }

                        <div className="button-group">
                            {/*<button type="button" className="button-m button-primary"*/}
                            {/*        onClick={() => shareContacts(type)} disabled={disableButton}>*/}
                            {/*    <span className="text">{buttonTitle}</span>*/}
                            {/*</button>*/}
                            <button type="button" className="button-m button-primary"
                                    onClick={handleCopyToClipboard}>
                                <span className="text">Copy Link</span>
                            </button>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}
