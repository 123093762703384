/**
 * Formats a URL to ensure it starts with http:// or https://
 * @param {string} url - The URL to format (can be http://, https://, www., or just the domain)
 * @returns {string} - The formatted URL
 */
function formatUrl(url) {
    // If the URL is null, undefined or an empty string, return an empty string
    if (!url || url.trim() === '') return '';
    
    // Clean any whitespace from the URL
    const trimmedUrl = url.trim();
    
    // If the URL already starts with http:// or https://, return it as is
    if (/^https?:\/\//i.test(trimmedUrl)) {
        return trimmedUrl;
    }
    
    // Otherwise, add https:// prefix
    return 'https://' + trimmedUrl;
}

/**
 * Validates if a URL has the correct format
 * @param {string} url - The URL to validate
 * @returns {boolean} - true if the URL is valid, false otherwise
 */
function isValidUrl(url) {
    if (!url) return false;
    
    try {
        // Make sure the URL has a protocol before trying to create a URL object
        const urlWithProtocol = formatUrl(url);
        new URL(urlWithProtocol);
        return true;
    } catch (e) {
        return false;
    }
}

export const UrlHelpers = {
    formatUrl,
    isValidUrl
} 