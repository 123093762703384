import React from 'react';
import { useForm } from "react-hook-form";
function MailingAddressForm({
     setAddress, setCity, setHasAddress, setAddress2, setHasCity, setHasState, setHasZip,
     setState, setZip, address2, city, state, zip, addressRef, hasAddress, hasCity, hasState,
     hasZip, formValidator, showHeader, isNewPackage=false, disableAddress=false, addressHeader="Mailing Address"
}) {
    const { register, formState: { errors } } = useForm({});

    return (
        <section className="form-details fz-block-sm">
            {showHeader ? <h2 className="h4">{addressHeader}</h2> : ''}
            <div className="form-row col-2">
                <div className={`form-field text-input ${!hasAddress ? 'error' : ''}`}>
                    <label htmlFor="address">Address</label>
                    <input
                        name='Street Address'
                        id='address'
                        type='text'
                        required
                        autoComplete="custom-no-autofill"
                        placeholder=' '
                        aria-required='true'
                        onBlur={(e) => {setHasAddress(true); setAddress(formValidator.clearEmojis(e.target.value))}}
                        onFocus={(e) => {setHasAddress(true);}}
                        onChange={(e) => {setHasAddress(true); setAddress(formValidator.clearEmojis(e.target.value))}}
                        {...register("address", { required: true })}
                        ref={addressRef}
                    />                                                                                                                         
                    {!hasAddress && (
                        <p className="validation-message">Enter address.</p>
                    )}
                </div>
                <div className="form-field text-input">
                    <label htmlFor='address2'>Apartment, suite, other (optional)</label>
                    <input
                        name='address2'
                        id='address2'
                        type='text'
                        aria-required='true'
                        autoComplete='address'
                        placeholder=' '
                        value={address2}
                        onBlur={(e) => {setAddress2(formValidator.clearEmojis(e.target.value))}}
                        onChange={(e) => {setAddress2(e.target.value); }}
                    />
                </div>
            </div>
            <div className="form-row col-3">
                <div className={`form-field text-input ${!hasCity ? 'error' : ''}`}>
                    <label htmlFor="city">City</label>
                    <input
                        name='City'
                        id='city'
                        type='text'
                        // disabled={isNewPackage || disableAddress}
                        autoComplete="off"
                        aria-required='true'
                        placeholder=' '
                        value={city}
                        onBlur={(e) => {setCity(formValidator.clearEmojis(e.target.value))}}
                        onFocus={(e) => {setHasCity(true);}}
                        onChange={(e) => {setCity(e.target.value); }}
                    />                                                                                                              
                    {!hasCity && ( 
                        <p className="validation-message">Enter city.</p>
                    )}
                </div>
                <div className={`form-field text-input ${!hasState ? 'error' : ''}`}>
                    <label htmlFor="state">State</label>
                    <input
                        type="text"
                        id="state"
                        // disabled={isNewPackage || disableAddress}
                        maxLength={2}
                        value={state.toUpperCase()}
                        onBlur={(e) => {setState(formValidator.clearEmojis(e.target.value.toUpperCase()))}}
                        onFocus={(e) => {setHasState(true);}}
                        onChange={(e) => {setState(e.target.value); }}
                    />
                    {!hasState && (
                        <p className="validation-message">Enter state.</p>
                    )}
                </div>
                <div className={`form-field text-input ${!hasZip ? 'error' : ''}`}>
                    <label htmlFor="address">Zip</label>
                    <input
                        name='Zip'
                        id='zip'
                        // disabled={isNewPackage || disableAddress}
                        type='text'
                        autoComplete="off"
                        pattern='^\d{5}(-\d{4})?$'
                        aria-required='true'
                        required
                        placeholder=' '
                        value={zip}
                        onFocus={(e) => {setHasZip(true);}}
                        onChange={(e) => {setZip(e.target.value); }}
                    />                                                                                                                  
                    {!hasZip && ( 
                        <p className="validation-message">Enter zip.</p>
                    )}
                </div>
            </div>
        </section>
    );
}

export default MailingAddressForm;
