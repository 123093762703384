/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react';
import { downloadThePackage } from '../../utils/downloadPackage';
import { useNavigate, useParams } from 'react-router-dom';
import AlertMessage from '../libs/AlertMessage';
import Axios from 'axios';
import BreadCrumbs from '../libs/BreadCrumbs';
import checkForMobile from '../../utils/checkForDeviceType';
import checkUserProfile from '../../utils/checkUserProfile';
import { getPackageInfo, getPackageImageInfo, getPackageImageInfoPublic } from '../../utils/getPackageInfo';
import iconCad from '../../images/icon-cad.svg';
import iconRvt from '../../images/icon-rvt.svg';
import iconObj from '../../images/icon-obj.svg';
import icon360 from '../../images/icon-360.svg';
import iconPictures from '../../images/icon-gallery.svg';
import iconDrone from '../../images/icon-drone.svg';
import iconPdf from '../../images/icon-pdf.svg';
import Header from '../libs/Header';
import ModalInfo from '../libs/ModalInfo';
import NoCoverImage from '../../images/NoCoverScopeImage.png';
import PackageDetails from '../libs/PackageDetails';
import { useGetPdfsAndPlanAndPictures } from "../libs/plan-and-picture/hooks/usePlanAndPicture";

export default function SurveyorPackageDetails() {
    const { packageName } = useParams();
    const [has360Pictures, setHas360Pictures] = useState(false);
    const [hasDronePictures, setHasDronePictures] = useState(false);
    const [hasfiles, setHasFiles] = useState(false);
    const [hasDwgPictures, setHasDwgPictures] = useState(false);
    const [hasObjPictures, setHasObjPictures] = useState(false);
    const [hasRvtPictures, setHasRvtPictures] = useState(false);
    const [hasNoPackage, setHasNoPackage] = useState(false);
    const [hasPdfs, setHasPdfs] = useState(false);
    const [hasStillPictures, setHasStillPictures] = useState(false);
    const [hasVideo, setHasVideo] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [numberOfViews, setNumberOfViews] = useState(0);
    const [packages, setPackages] = useState([]);
    const [scopeOfWorkVideo, setScopeOfWorkVideo] = useState('');
    const [scopeOfWorkThumbnail, setScopeOfWorkVideoThumbnail] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showMore, setShowMore] = useState({});
    const [showNotAllowed, setShowNotAllowed] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [singlePackage, setSinglePackage] = useState([]);
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    const userId = parseInt(sessionStorage.getItem('userId'));
    const userType = parseInt(sessionStorage.getItem('userType'));
    const userTypeToBeChecked = parseInt(process.env.REACT_APP_SURVEYOR_USER_TYPE_TO_BE_CHECKED);
    const leadtype = 0;
    const navigate = useNavigate();
    const handleGoToLogin = () => navigate('/login');
    sessionStorage.setItem('userType', userTypeToBeChecked);
    const { planAndPictures, loading } = useGetPdfsAndPlanAndPictures(packageName);

    useEffect(() => {

        setIsMobile(checkForMobile());
        getPackage(packageName);
        /*if (isLoggedIn === 'true') {
            try {
                getUserProfile(userId, userType, userTypeToBeChecked);
            } catch (err) {
                console.log('error trying to get the package id = ', err);
            };
        } else {
            handleGoToLogin();
        }*/
    }, []);

    const getPackageViews = (packageName, userId) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getSurveyorPackagesStats`, { packageName: packageName, userId: userId }).then((response) => {
            getPackageImage(packageName);
            setNumberOfViews(response.data.length)
        }).catch(function (error) {
            console.log('download package error = ', error);
        })
    }

    const downloadPackage = async (pckId) => {
        setIsDownloading(true);
        try {
            await downloadThePackage(pckId);
            setIsDownloading(false);
        } catch (e) {
            setIsDownloading(false);
            console.error(`Download package error: ${e}`);
        }
    }

    const goTopage = (type, pageNameTitle, packageName) => {
        if (type === 'survey') {
            navigate(`/survey`);
        } else if (pageNameTitle === 'pdf') {
            navigate(`/pdf/${packageName}/${leadtype}`)
        } else if (pageNameTitle === 'dwg') {
            // navigate(`/dwg/${packageName}/${leadtype}`)
            navigate(`/galleries/${packageName}/dwg/${leadtype}`)
        } else if (pageNameTitle === 'obj') {
            // navigate(`/dwg/${packageName}/${leadtype}`)
            navigate(`/galleries/${packageName}/obj/${leadtype}`)
        } else if (pageNameTitle === 'rvt') {
            // navigate(`/dwg/${packageName}/${leadtype}`)
            navigate(`/galleries/${packageName}/rvt/${leadtype}`)
        } else if (pageNameTitle === 'still') {
            navigate(`/galleries/${packageName}/still-pictures/${leadtype}`)
        } else if (pageNameTitle === '360') {
            navigate(`/galleries/${packageName}/three-sixty-pictures/${leadtype}`)
        } else {
            // navigate(`/gallery/${packageName}/${type}/${leadtype}`);
            navigate(`/galleries/${packageName}/${type}/${leadtype}`)
        }
    }

    const getPackageDescription = (packageDesc, index) => {
        if (!showMore[index]) {
            if (packageDesc.length > process.env.REACT_APP_DISPLAY_TEXT_LENGTH) {
                return `${packageDesc.substring(0, process.env.REACT_APP_DISPLAY_TEXT_LENGTH).trimRight()}... `;
            } else {
                return packageDesc.substring(0, process.env.REACT_APP_DISPLAY_TEXT_LENGTH);
            }
        } else {
            return packageDesc;
        }
    }

    const handleReadMore = (index) => {
        setShowMore({ ...showMore, [index]: !showMore[index] });
    }

    const getPackageImage = async (pck) => {
        if (!pck?.packageName) {
            return;
        }

        const packageImageInfo = await getPackageImageInfoPublic(pck);
        if (packageImageInfo?.response[0]?.Key) {
            setScopeOfWorkVideoThumbnail(packageImageInfo.scopeOfWorkVideoThumbnail);
            setScopeOfWorkVideo(packageImageInfo.scopeOfWorkVideo);
            setHasVideo(packageImageInfo.hasVideo);
        } else {
            setHasVideo(false);
        }
    }

    const getUserProfile = async (userId, userType, userTypeToBeChecked) => {
        const { isGoodToGo } = await checkUserProfile(userId, userType, userTypeToBeChecked);
        if (isGoodToGo) {
            setIsMobile(checkForMobile());
            getPackage(packageName);
        } else {
            setShowNotAllowed(true);
        }
    }

    const getPackage = async (packageName) => {

        try {
            const packageInfo = await getPackageInfo(packageName);
            if (packageInfo.response) {
                if (packageInfo.response.userId !== userId) {
                    setShowNotAllowed(true);
                } else {
                    setShowNotAllowed(false);
                    setHasFiles(packageInfo.hasFiles);
                    setHas360Pictures(packageInfo.has360Pictures);
                    setHasDronePictures(packageInfo.hasDronePictures);
                    setHasDwgPictures(packageInfo.hasDwg);
                    setHasObjPictures(packageInfo.hasObj);
                    setHasRvtPictures(packageInfo.hasRvt);
                    setHasPdfs(packageInfo.hasPdfs);
                    setHasStillPictures(packageInfo.hasStillPictures);
                    setPackages([packageInfo.response]);
                    getPackageImage(packageInfo.response);
                    getPackageViews(packageName, packageInfo.response.userId);
                }
            } else {
                setHasNoPackage(true);
            }
            setShowLoader(false);
        } catch (err) {
            setShowLoader(false);
        }
    }


    const deletePackage = () => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/deletePackage`,
            {
                packageId: singlePackage.packageId,
                packageName: singlePackage.packageName,
                userId: userId,
                packageAddress: `${singlePackage.packageAddress}${singlePackage.packageAddress2.length > 0 ? ` ${singlePackage.packageAddress2},` : ','} ${singlePackage.packageCity}, ${singlePackage.packageState} ${singlePackage.packageZip}`
            }).then((response) => {
                if (typeof response.data !== 'undefined' && response.data.affectedRows === 1) {
                    setShowDeleteModal(false);
                    navigate(`/survey`);
                }
            }).catch(function (error) {
                console.log(error);
            })
    };

    const invitePro = () => {
        navigate(`/contacts/${userId}/500002/${packageName}`);
    }

    return (
        <div id="fazzad-site-container">
            <Header />
            <main id="fazzad-main">
                <div id="app-container">
                    <BreadCrumbs
                        header1={'Home'}
                        link1={'survey'}
                        header2={'Package Details'}
                        link2={``}
                        isLink1Clickable={true}
                        isLink2Clickable={false}
                        isLink2Navigation={false}
                        header3={''}
                        link3={''}
                        setIsPackageUpdated={false}
                        setUploadMoreFiles={false}
                    />
                    {(showLoader || loading) && (<span className="loader loader--l"></span>)}
                    {packages.length > 0 ?
                        <>
                            {packages.length > 0 && (
                                <PackageDetails
                                    downloadPackage={downloadPackage}
                                    filesText={'Files'}
                                    getPackageDescription={getPackageDescription}
                                    goTopage={goTopage}
                                    handleReadMore={handleReadMore}
                                    has360Pictures={has360Pictures}
                                    hasDronePictures={hasDronePictures}
                                    hasDwgPictures={hasDwgPictures}
                                    hasObjPictures={hasObjPictures}
                                    hasRvtPictures={hasRvtPictures}
                                    hasFiles={hasfiles}
                                    hasPdfs={hasPdfs}
                                    hasStillPictures={hasStillPictures}
                                    hasVideo={hasVideo}
                                    headerTitle={'Package Details'}
                                    iconPdf={iconPdf}
                                    iconPictures={iconPictures}
                                    icon360={icon360}
                                    iconDrone={iconDrone}
                                    iconCad={iconCad}
                                    iconRvt={iconRvt}
                                    iconObj={iconObj}
                                    NoCoverImage={NoCoverImage}
                                    leadtype={leadtype}
                                    numberOfViews={numberOfViews}
                                    packages={packages}
                                    packageDescriptionHeader={'Scope of work'}
                                    packageName={packageName}
                                    scopeOfWorkVideo={scopeOfWorkVideo}
                                    scopeOfWorkThumbnail={scopeOfWorkThumbnail}
                                    setShowDeleteModal={setShowDeleteModal}
                                    setSinglePackage={setSinglePackage}
                                    showContactHomeOnwerButton={false}
                                    showDeletePackageButton={true}
                                    showEditPackageButton={true}
                                    showDownloadPackageButton={true}
                                    showHeaderButtons={true}
                                    showMore={showMore}
                                    toggleCreditCardModal={null}
                                    videoText={'Walkthrough Video'}
                                    currentPage={'surveyor package details'}
                                    userId={userId}
                                    userType={userType}
                                    videoName={scopeOfWorkVideo}
                                    showInviteButton={true}
                                    invitePro={invitePro}
                                    planAndPictures={planAndPictures}
                                    viewType={'surveyor'}
                                    showJobTags={true}
                                    selectedTags={{
                                        design: packages[0]?.tagsDesign?.split(',') || [],
                                        construction: packages[0]?.tagsConstruction?.split(',') || []
                                    }}
                                />
                            )}
                            {showDeleteModal ?
                                <div id="modal-5" className="modal-container">
                                    <div className="modal">
                                        <div className="modal__header">
                                            <h2 className="modal-title h4">{process.env.REACT_APP_DELETE_PACKAGE_MESSAGE}</h2>
                                        </div>
                                        <div className="modal__action">
                                            <div className="button-group">
                                                <button className="button button-m button-secondary" type="button" onClick={() => { setShowDeleteModal(false) }}>No, Cancel</button>
                                                <button className="button button-m button-destructive" type="button" onClick={() => { deletePackage() }}>Yes, Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''
                            }
                        </>
                        :
                        hasNoPackage ?
                            <AlertMessage
                                messageHeader={"No packages found."}
                                messageBody={"The package you are trying to edit does not exist or doesn't belong to you."}
                                startDate={null}
                                setShowWarningContainerMessage={''}
                                messageType={"informational"}
                                showButton={false}
                            />
                            : ''
                    }
                    {isDownloading ?
                        <ModalInfo
                            messageHeader={"Downloading your package..."}
                            messageBody={`Files will be saved on your ${isMobile ? 'mobile device' : 'computer'}.`}
                            messageType={"informational"}
                            showCloseButton={false}
                            showLoader={true}
                        />
                        : ''
                    }
                </div>
            </main>
            {showNotAllowed ?
                <div className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4 warning">You are not allowed to see this page.</h2>
                            <button type="button" className="button-tertiary button-s button-icon modal-close" onClick={() => navigate('/')}>
                                <span className="icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17 7L7 17M7 7L17 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal__content">
                            <p>You are not allowed to view this page or this package.</p>
                        </div>
                    </div>
                </div>
                : ''
            }
            {showDeleteModal ?
                <div id="modal-5" className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4">{process.env.REACT_APP_DELETE_PACKAGE_MESSAGE}</h2>
                        </div>
                        <div className="modal__action">
                            <div className="button-group">
                                <button className="button button-m button-secondary" type="button" onClick={() => { setShowDeleteModal(false) }}>No, Cancel</button>
                                <button className="button button-m button-destructive" type="button" onClick={() => { deletePackage() }}>Yes, Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
                : ''
            }
        </div>
    )
}
