import React from 'react';
import {copyToClipBoard} from '../utils/copyToClipBoard';
import getSvg from "../utils/getSvg";

function TitleWithCopyButton({headerText, textToCopy, disabled = false}) {
    const handleCopyToClipboard = () => {
        copyToClipBoard(textToCopy);
    };

    return (
            <small className="fz-ai-center fz-fd-row fz-gap-icon">
                {headerText}
                <button
                    title="Copy!"
                    className="button button-icon button-xs"
                    onClick={() => {
                        if (!disabled) {
                            handleCopyToClipboard()
                        }
                    }}
                    disabled={disabled}
                >
                    <span className="icon">
                        {getSvg('copy')}
                    </span>
                </button>
            </small>
    );
}

export default TitleWithCopyButton; 
