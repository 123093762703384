import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import getSvg from '../../utils/getSvg';
import imageLogo from '../../images/fazzad-logo.svg';
import imageLogoWhite from '../../images/fazzad-logo-white.svg';

export default function CustomHeader(props) {

    const [isDarkModeActive, setIsDarkModeActive] = useState(false);


    const navigate = useNavigate();
    const goToPage = (vl) => {
        switch (vl) {
            case 'responses':
                if (parseInt(props.leadtype) === 0) {
                    navigate(`/responses/${props.packageName}/${parseInt(props.leadtype)}`);
                } else {
                    navigate(`/responses/${sessionStorage.getItem('leadId')}/${parseInt(props.leadtype)}`);
                }
                break;
            case 'details':
                if (parseInt(props.leadtype) === 0) {
                    navigate(`/showdetails/${props.packageName}/${parseInt(props.leadtype)}`);
                } else {
                    navigate(`/leads/${sessionStorage.getItem('leadId')}/${parseInt(props.leadtype)}`);
                }
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        const urlPattern = /^\/responses\/[a-f0-9\-]+\/0$/;
        if (urlPattern.test(window.location.pathname)) {
            setIsDarkModeActive(true);
        } else {
            setIsDarkModeActive(false);
        }
    })

    return (
        <>
            <header id="fazzad-header" className="app-header">
                <div className={'fazzad-brand'}>
                    <a href="/" className="logo" onClick={(e) => {
                        e.preventDefault();
                        e.target.blur();
                    }}>
                        <img src={isDarkModeActive ? imageLogoWhite : imageLogo} alt="Fazzad"/>
                    </a>
                    <small>Unlock your property's value</small>
                </div>

                {parseInt(props.leadtype) === 0 && (
                    <nav id="po-navigation">
                        <ul>
                            <li>
                                <a href="/" onClick={(e) => {
                                    e.preventDefault();
                                    goToPage('responses')
                                }} className="button button-m">
                                    {getSvg('responses')}
                                    <span className="text">Responses</span>
                                </a>
                            </li>
                            <li>
                                <a href="/" onClick={(e) => {
                                    e.preventDefault();
                                    goToPage('details')
                                }} className="button button-m">
                                    {getSvg('survey')}
                                    <span className="text">Survey</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                )}
            </header>
        </>
    )
};
