/* eslint-disable jsx-a11y/anchor-is-valid */
import Axios from 'axios';
import Header from '../libs/Header';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import AppContext from '../../AppContext';
import { logIn } from '../../utils/authHelpers';
import getSvg from '../../utils/getSvg';
// userTypes:
// 500001 - surveyor
// 500002 - pro
// 500003 - home owner
// 500004 - free pro

export default function Login() {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState("false");
    const [showVideoModal, setShowVideoModal] = useState(false);
    sessionStorage.setItem('currentPackageId', '');
    sessionStorage.setItem('email', '');
    sessionStorage.setItem('isAdmin', '0');
    sessionStorage.setItem('isPro', '0');
    sessionStorage.setItem('packageAddress', '');
    sessionStorage.setItem('packageId', '');
    sessionStorage.setItem('packageName', '');
    sessionStorage.setItem('userName', '');
    sessionStorage.setItem('userType', 500001);
    sessionStorage.setItem('videoFileName', '');
    sessionStorage.setItem('userId', '');

    const { email, setUserName, setUserType, setEmail, setIsAdmin, setIsLoggedIn, setUserInfo, setAccessToken } = useContext(AppContext);

    const handleGoToPage = (page) => {
        if (page === 'forgotPassword') {
            navigate(`/forgotpassword/${uuid()}`);
        } else {
            navigate(`/signup`);
        }
    }

    useEffect(() => {
        sessionStorage.setItem('isLoggedIn', 'false');
        sessionStorage.setItem('userName', ``)
        sessionStorage.setItem('userId', '');
        sessionStorage.setItem('userType', 0);
        sessionStorage.setItem('email', '');
        sessionStorage.setItem('isAdmin', false);
        sessionStorage.setItem('isPro', false);
        sessionStorage.setItem('isPropertyOwner', false);
        sessionStorage.setItem('userState', '');
        setEmail('');
        setPassword('');
    }, [showErrorMessage]);

    const checkLogin = () => {
        logIn(email, password).then((response) => {
            if (response) {
                setShowErrorMessage("false");
                sessionStorage.setItem('userName', response.firstName);
                sessionStorage.setItem('userState', response.state);
                setUserName(response.firstName);
                sessionStorage.setItem('userId', response.userId);
                sessionStorage.setItem('userType', response.userType);
                setUserType(response.userType);
                sessionStorage.setItem('email', response.email);
                setEmail(response.email);
                sessionStorage.setItem('isAdmin', response.isAdmin);
                setIsAdmin(response.isAdmin);
                sessionStorage.setItem('isLoggedIn', 'true');

                setUserInfo(response);
                response?.accessToken && setAccessToken(response.accessToken);

                switch (response.userType) {
                    case 500001:
                        navigate(`/survey`);
                        break;
                    case 500002:
                        sessionStorage.setItem('isPro', true);
                        navigate(`/profeed`);
                        break
                    case 500003:
                        navigate(`/newlead`);
                        break;
                    case 500004:
                        navigate(`/profeed`);
                        break;
                }
            } else {
                sessionStorage.setItem('isLoggedIn', 'false');
                setShowErrorMessage("true");
            }
        }).catch(function (error) {
            console.log(error);
            sessionStorage.setItem('isLoggedIn', 'false');
            setShowErrorMessage("true");
        })
    };

    return (
        <div id="fazzad-site-container">
            <Header />
            <main id="fazzad-main">
                <div id="app-container" className="fz-full-width">
                    <figure>
                        <img
                            className="value-video modal-trigger"
                            data-modal="modal-7"
                            src="https://fazzad-courses.s3.us-west-1.amazonaws.com/tutorials/thumbnails/photo-00-pitch.png"
                            alt="Fazzad welcome video clip"
                            onClick={(e) => {
                                e.preventDefault();
                                setShowVideoModal(true);
                            }}
                        />
                    </figure>
                    <div id="page-title">
                        <h1 className="h3">Welcome back</h1>
                    </div>
                    {showErrorMessage === "true" && (
                        <div className="demo-container">
                            <div className="alert-container">
                                <div className="alert alert--error">
                                    <div className="alert__content">
                                        <p className="alert__heading">Login not found with the credentials provided.
                                            Please verify the information and try it again.</p>
                                    </div>
                                    <button type="button" className="button-tertiary button-s button-icon alert__close"
                                            onClick={() => setShowErrorMessage("false")}>
                                        <span className="icon">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17 7L7 17M7 7L17 17" stroke="currentColor" strokeWidth="2"
                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="form-container" id="login">
                        <form action="">
                            <section className="form-login">
                                <div className="form-field text-input">
                                    <label htmlFor="email">Email</label>
                                    <div className="input-container">
                                        <input
                                            id='email'
                                            type='email'
                                            autoComplete=''
                                            value={email}
                                            required
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                            onFocus={(e) => {
                                                setShowErrorMessage("false");
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="form-field text-input">
                                    <label htmlFor="password">Password</label>
                                    <div className="input-container">
                                        <input
                                            id='password'
                                            type='password'
                                            required
                                            value={password}
                                            autoComplete=''
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                            }}
                                            onFocus={(e) => {
                                                setShowErrorMessage("false");
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="form-field fz-fd-row fz-gap-x">
                                    <a href="/" onClick={(e) => {
                                        e.preventDefault();
                                        handleGoToPage('forgotPassword')
                                    }}>Forgot password</a>
                                    <a href="/" onClick={(e) => {
                                        e.preventDefault();
                                        handleGoToPage('signup')
                                    }}>Sign-up</a>
                                </div>
                                <div className="button-group">
                                    <button
                                        aria-label='Login credentials'
                                        className="button-primary button-l"
                                        onClick={checkLogin}
                                        type='button'
                                    >
                                        <span className="text">Submit</span>
                                    </button>
                                </div>
                            </section>
                        </form>
                    </div>
                </div>
                {showVideoModal && (
                    <div id="modal-7" className="modal-container">
                        <div className="modal--video">
                            <button
                                type="button"
                                className="button-tertiary button-s button-icon modal-close"
                                onClick={() => setShowVideoModal(false)}
                            >
                                {getSvg('close')}
                            </button>
                            <video
                                src="https://fazzad-courses.s3.us-west-1.amazonaws.com/tutorials/Pitch.mp4"
                                poster="https://fazzad-courses.s3.us-west-1.amazonaws.com/tutorials/thumbnails/photo-00-pitch.png"
                                controls
                                autoPlay
                            />
                        </div>
                    </div>
                )}
            </main>
        </div>
    )
}
