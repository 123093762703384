/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, useRef} from 'react';
import {cleanMediaUrl} from '../../utils/clearMediaUrl';
import {useNavigate, useParams} from 'react-router-dom';
import PageTitle from './PageTitle';
import Axios from 'axios';
import Header from './Header';
import graphicFolder from '../../images/graphic-folder-black-white.png';
import getSvg from '../../utils/getSvg';
import PdfList from "./plan-and-picture/pdf-list/pdf-list";
import PdfThumbnailsModal from "./plan-and-picture/page-select-modal/plan-and-picture.select-page-modal";
import {file, folder} from "jszip";
import {ImageWithPresignedUrl} from "./ImageWithPresignedUrl";
import {uploadWithSignedUrl} from "../../utils/awsHelpers";
import axiosInstance from '../../utils/axiosInstance';
import {getBucketNameByViewType, ViewType, getFileExt} from "../../utils/packageHelper";
import {VideoWithPresignedUrl} from "../libs/VideoWithPresignedUrl";
import trashicon from "../../images/icon-trash-03.svg";
import {AutodeskViewerHelpers} from "../../utils/autodeskViewerHelpers";
import iconDwg from '../../images/icon-dwg-x2.svg';
import iconObj from '../../images/icon-obj-x2.svg';
import iconRvt from '../../images/icon-rvt-x2.svg';
import {userIsEnabled} from "./FeatureFlagRoute";

window.Buffer = window.Buffer || require("buffer").Buffer;

export default function EditPackagePictures({edit = true}) {
    const navigate = useNavigate();
    const {packageName, pictureType} = useParams();
    const [currentIndex, setCurrentIndex] = useState(0)
    const [errorMessage, setErrorMessage] = useState('Just images with format .jpg or .jpeg or .png can be uploaded');
    const [folderStructure, setFolderStructure] = useState({});
    const [selectedPdf, setSelectedPdf] = useState(undefined);
    const [showDeleteButton, setShowDeleteButton] = useState(true);
    const [foldersFiles, setFoldersFiles] = useState([]);
    const [packageData, setPackageData] = useState({});
    const [pictureTypeDisplay, setPictureTypeDisplay] = useState('');
    const [thePictureType, setThePictureType] = useState('cover-image');
    const [selectedPictures, setSelectedPictures] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [showNoFilesToDelete, setShowNoFilesToDelete] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [newScopeOfWorkVideoUrl, setNewScopeOfWorkVideoUrl] = useState('');
    const [newFolderName, setNewFolderName] = useState('');
    const [showProcessing, setShowProcessing] = useState(false);
    const [showWrongImageFormat, setShowWrongImageFormat] = useState(false);
    const [showNewFolderModal, setShowNewFolderModal] = useState(false);
    const [uploadTypeText, setUploadTypeText] = useState('picture');
    const fileInputRef = useRef();
    const [currentPath, setCurrentPath] = useState([]);
    const [currentOption, setCurrentOption] = useState("Cover Image");
    const [allUsedPhotos, setAllUsedPhotos] = useState([]);

    const [duplicatedFolderName, setDuplicatedFolderName] = useState('');
    const [hasFolderName, setHasFolderName] = useState(true);

    const [folderToDelete, setFolderToDelete] = useState('');

    const imageExtensionsRegex = /\.(jpg|jpeg|png)$/i;
    const cadExtensionsRegex = /\.(dwg)$/i;
    const objExtensionsRegex = /\.(zip|obj)$/i;
    const rvtExtensionsRegex = /\.(rvt)$/i;
    const pdfExtensionsRegex = /\.(pdf)$/i;
    const videoExtensionsRegex = /\.(mp4|mov|avi|wmv|flv|webm)$/i;
    const bucketPrefix = 'https://fazzad-projects-compressed.s3.us-west-1.amazonaws.com';
    const s3BucketPrefixVideo = process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX;
    const videoBucket = process.env.REACT_APP_SCOPE_OF_WORK;
    const videoBucketPreview = process.env.REACT_APP_S3_PROJECTS_VIDEOS_PREFIX;
    const videoPreviewBucket = process.env.REACT_APP_PROJECT_VIDEOS_BUCKET_NAME;
    let wrongFiles = [];
    const bucketName = 'fazzad-projects';
    const filesToBeDeleted = [];
    let scopeOfWorkVideoUrl = '';
    let scopeOfWorkVideoUrlVideo = '';
    const config = {
        bucketName: bucketName,
        region: process.env.REACT_APP_REGION,
    };
    const configScopeOfWorkUpdateVideo = {
        bucketName: 'fazzad-projects-videos-source',
        region: process.env.REACT_APP_REGION,
    };
    const [currentFolder, setCurrentFolder] = useState('');

    useEffect(() => {
        // const localStorageOption = localStorage.getItem('currentOption');
        const localStorageOption = 'cover-image';
        if (typeof pictureType !== 'undefined') {
            getFileType(pictureType);
            getPackageImage(packageName, pictureType);
        } else if (localStorageOption !== null) {
            const localStoragePackage = localStorageOption === 'plan-picture' ? 'pdf-files' : localStorageOption;
            getFileType(localStorageOption);
            getPackageImage(packageName, localStoragePackage);
        } else {
            getFileType('cover-image');
            getPackageImage(packageName, 'cover-image');
        }
    }, [packageName, pictureType]);


    // const addSelectedPicture = (e, file) => {
    //     console.log('file = ', file);
    //     setSelectedPictures((currentSelectedPictures) => {
    //         // Check if the file is already selected
    //         console.log('currentSelectedPictures = ', currentSelectedPictures);
    //         if (currentSelectedPictures.includes(file)) {
    //             // If selected, remove it from the array
    //             console.log(1)
    //             return currentSelectedPictures.filter(item => item !== file);
    //         } else {
    //             console.log(2)
    //             // If not selected, add it to the array
    //             return [...currentSelectedPictures, file];
    //         }
    //     });
    // };

    const addSelectedPicture = (e, file) => {
        // e.preventDefault(); // Stop the form from submitting

        const newSelectedPictures = selectedPictures.some(f => f.Key === file.Key)
            ? selectedPictures.filter(f => f.Key !== file.Key)
            : [...selectedPictures, file];

        setSelectedPictures(newSelectedPictures);
    };

    const getFileType = (pictureType) => {
        switch (pictureType) {
            case 'still-pictures':
                setPictureTypeDisplay('Still Pictures');
                setCurrentOption('Still Pictures');
                setThePictureType('still-pictures');
                setUploadTypeText('picture');
                break;
            case 'three-sixty-pictures':
                setCurrentOption('360 Pictures');
                setPictureTypeDisplay('360 Pictures');
                setThePictureType('three-sixty-pictures');
                setUploadTypeText('picture');
                break;
            case 'drone-pictures':
                setPictureTypeDisplay('Drone Pictures');
                setCurrentOption('Drone Pictures');
                setThePictureType('drone-pictures');
                setUploadTypeText('picture');
                break;
            case 'cad-file':
            case 'dwg-files':
                setPictureTypeDisplay('DWG File');
                setCurrentOption('DWG File');
                setThePictureType('dwg-files');
                setUploadTypeText('file');
                break;
            case 'obj-files':
                setPictureTypeDisplay('OBJ File');
                setCurrentOption('OBJ File');
                setThePictureType('obj-files');
                setUploadTypeText('file');
                break;
            case 'rvt-files':
                setPictureTypeDisplay('RVT File');
                setCurrentOption('RVT File');
                setThePictureType('rvt-files');
                setUploadTypeText('file');
                break;
            case 'pdf-files':
                setPictureTypeDisplay('PDF Files');
                setCurrentOption('PDF Files');
                setThePictureType('pdf-files');
                setUploadTypeText('file');
                break;
            case 'plan-picture':
                setCurrentOption('plan-picture');
                setUploadTypeText('file');
                setThePictureType('pdf-files');
                setPictureTypeDisplay('PDF Files');
                break;
            case 'cover-image':
                setPictureTypeDisplay('Cover Image');
                setCurrentOption('Cover Image');
                setThePictureType('cover-image');
                setUploadTypeText('image');
                break;
            case 'scope-of-work':
                setPictureTypeDisplay('Walkthrough Video');
                setCurrentOption('Walkthrough Video');
                setThePictureType('scope-of-work');
                setUploadTypeText('video');
                break;
            default:
                setPictureTypeDisplay('Cover Image');
                setCurrentOption('Cover Image');
                setThePictureType('cover-image');
                setUploadTypeText('image');
                break;
        }
    }

    const goToFeed = () => {
        navigate(`/survey`);
    }

    const clearPackageUI = () => {
        setFolderStructure({});
        setCurrentPath([]);
        setCurrentFolder("");
    }

    const getPackageImage = async (packageName, pictureType, updated = false) => {
        // Guardar la ruta actual antes de limpiar la UI
        let tempPath = updated ? currentPath : [];

        if (!updated) {
            clearPackageUI();
        }

        if (pictureType === 'cad-file') {
            pictureType = 'dwg-files';
        }
        if (packageName) {
            try {
                setShowLoading(true);
                const response = await axiosInstance.post(`${process.env.REACT_APP_LOCALHOST}/uniqueImage`, {
                    packageName: packageName,
                    pictureType: pictureType
                });

                if (updated) {
                    clearPackageUI();
                }

                const tempData = refactorObject(response.data);

                if (pictureType === 'still-pictures'
                    || pictureType === 'three-sixty-pictures'
                    || pictureType === 'drone-pictures'
                    || pictureType === 'dwg-files'
                    || pictureType === 'obj-files'
                    || pictureType === 'rvt-files') {
                    if (Array.isArray(response.data)) {
                        setFolderStructure(tempData);

                        // Verificar si la ruta actual sigue siendo válida después de la actualización
                        if (tempPath.length > 0) {
                            let currentLevel = tempData;
                            let validPath = true;

                            // Verificar que cada segmento de la ruta exista en la estructura actualizada
                            for (let i = 0; i < tempPath.length; i++) {
                                if (currentLevel[tempPath[i]]) {
                                    currentLevel = currentLevel[tempPath[i]];
                                } else {
                                    validPath = false;
                                    break;
                                }
                            }

                            // Solo establecer la ruta si sigue siendo válida
                            if (validPath) {
                                setCurrentPath(tempPath);
                            } else {
                                // Si la ruta ya no es válida (por ejemplo, si se eliminó la carpeta), volver a la raíz
                                setCurrentPath([]);
                            }
                        } else {
                            setCurrentPath([]);
                        }
                    } else {
                        setFolderStructure({});
                        setCurrentPath([]);
                    }
                } else {
                    if (response.data.length > 0) {
                        setFoldersFiles(response.data);
                    } else {
                        setFoldersFiles([]);
                    }
                }

                setShowLoading(false);

            } catch (error) {
                setShowLoading(false);
                console.error('Error fetching package images:', error);
            }
        }
    };

    const updatePath = (path) => {
        setCurrentPath(path);
        setCurrentFolder(path);
    };

    const resetPath = () => {
        setCurrentPath([]);
    };

    const refactorObject = (data) => {
        const newObj = {};
        if (data.length > 0) {
            data.forEach((item) => {
                const keyParts = item.Key.split('/').slice(1);
                const fileName = keyParts.pop();
                let currentObj = newObj;

                keyParts.forEach((part, index) => {
                    if (!currentObj[part]) {
                        if (index === keyParts.length - 1) {
                            currentObj[part] = {
                                [fileName]: {
                                    "_isFile": true,
                                    ...item
                                }
                            };
                        } else {
                            currentObj[part] = {};
                        }
                    } else if (index === keyParts.length - 1) {
                        currentObj[part][fileName] = {
                            "_isFile": true,
                            ...item
                        };
                    }
                    currentObj = currentObj[part];
                });
            });
        }

        const keys = Object.keys(newObj);

        return keys.length > 0 ? newObj[keys[0]] : newObj;
    };

    const getFilesAndFolders = (folderStructure) => {
        const files = [];
        const folders = [];

        const traverseFolder = (folder, path = '') => {
            for (const key in folder) {
                const item = folder[key];
                const currentPath = path ? `${path}/${key}` : key;

                if (item._isFile === true && !currentPath.endsWith('/')) {
                    files.push({...item, path: currentPath});
                } else if (typeof item === 'object' && (!item._isFile || currentPath.endsWith('/'))) {
                    folders.push({name: key, path: currentPath, _isFile: false});
                    traverseFolder(item, currentPath); // Recursive call for nested folders
                }
            }
        };

        traverseFolder(folderStructure);

        return {files, folders};
    };

    const updateFilesSelected = (e) => {
        e.preventDefault();
        wrongFiles = [];
        const files = [...e.target.files];
        setSelectedPictures(e.target.files);
        if (e.target.files.length > 0) {
            files.map((file) => {
                if (thePictureType === 'three-sixty-pictures' || thePictureType === 'still-pictures' || thePictureType === 'drone-pictures' || thePictureType === 'cover-image') {
                    if (!imageExtensionsRegex.test(file.name)) {
                        setErrorMessage('Just images with format .jpg or .jpeg or .png can be uploaded');
                        wrongFiles.push(file.name);
                    } else {
                        const fileSizeInbytes = file.size;
                        const fileSizeInMB = fileSizeInbytes / (1024 * 1024);

                        if (fileSizeInMB > 100) {
                            setErrorMessage('Image file size exceeds the maximum limit. Please choose a smaller image.');
                            wrongFiles.push(file.name);
                        }
                    }
                } else if (thePictureType === 'scope-of-work') {
                    if (!videoExtensionsRegex.test(file.name)) {
                        setErrorMessage('Just video files with format .mp4 or .mov or .avi or. wmv or .webm can be uploaded');
                        wrongFiles.push(file.name);
                    }
                } else if (thePictureType === 'dwg-files') {
                    if (!cadExtensionsRegex.test(file.name)) {
                        setErrorMessage('Just files with format .dwg can be uploaded');
                        wrongFiles.push(file.name);
                    }
                } else if (thePictureType === 'obj-files') {
                    if (!objExtensionsRegex.test(file.name)) {
                        setErrorMessage('Just files with format .obj or .zip can be uploaded');
                        wrongFiles.push(file.name);
                    }
                } else if (thePictureType === 'rvt-files') {
                    if (!rvtExtensionsRegex.test(file.name)) {
                        setErrorMessage('Just files with format .rvt can be uploaded');
                        wrongFiles.push(file.name);
                    }
                } else if (thePictureType === 'pdf-files') {
                    if (!pdfExtensionsRegex.test(file.name)) {
                        setErrorMessage('Just files with format .pdf can be uploaded');
                        wrongFiles.push(file.name);
                    }
                }

            });
            if (wrongFiles.length === 0) {
                setShowWrongImageFormat(false);
                showConfirmModal();
            } else {
                setShowWrongImageFormat(true);
            }
        } else {
            setSelectedPictures([]);
        }
    }

    const showConfirmModal = () => {
        setShowModal(true);
    }

    const checkForDeletion = (e) => {
        if (selectedPictures.length > 0) {
            setShowDeleteModal(true);
            setShowNoFilesToDelete(false);
        } else {
            setShowNoFilesToDelete(true);
            setShowDeleteModal(false);
        }
    }

    const updateFolderStructure = (folderStructure, fileStructure) => {
        const deleteFileFromFolder = (folder, fileName) => {
            for (const key in folder) {
                const item = folder[key];
                if (item._isFile && item.Key.includes(fileName)) {
                    delete folder[key];
                } else if (typeof item === 'object' && !item._isFile) {
                    deleteFileFromFolder(item, fileName); // Recursive call for nested folders
                }
            }
        };

        fileStructure.forEach(file => {
            const fileName = file.Key.split('/').pop(); // Extract the filename
            deleteFileFromFolder(folderStructure, fileName);
        });

        return folderStructure;
    };

    const convertToArray = obj => {
        return Object.keys(obj).map(key => ({
            name: key,
            ...obj[key],
        }));
    };

    const updateCurrentFilesOnDb = (packageName, pictureType, selectedPictures) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/updateFileOnDb`, {
            packageName: packageName,
            pictureType: pictureType,
            filesName: cleanMediaUrl(selectedPictures[0].name),
        }).then((response) => {
            setNewScopeOfWorkVideoUrl(cleanMediaUrl(selectedPictures[0].name));
        }).catch(function (error) {
            console.log('error = ', error);
        });
    };

    const deleteFilesFromS3 = async (type = 'files') => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/deleteFilesFromPackage`, {
            isVideo: false,
            isFromFolder: true,
            packageName: packageName,
            bucketName: bucketName,
            filesNames: type === 'files' ? selectedPictures : filesToBeDeleted,
        }).then((response) => {
            const selectedPicturesKeys = new Set(selectedPictures.map(pic => pic.Key));

            if (thePictureType === 'dwg-files'
                || thePictureType === 'obj-files'
                || thePictureType === 'rvt-files'
                || thePictureType === 'cad-file') {
                selectedPicturesKeys.forEach(key => {
                    let fileName = AutodeskViewerHelpers.getFileNameFromFilePath(key, packageName, thePictureType)

                    let urn = AutodeskViewerHelpers.getUrn(packageName, fileName);

                    AutodeskViewerHelpers.deleteFileTranslation(urn).then(response => {
                        AutodeskViewerHelpers.deleteBucketFile(packageName, fileName);
                    });
                })
            }

            let remainingFiles = [];
            if (response.data.Deleted.length > 0) {
                if (thePictureType === 'still-pictures'
                    || thePictureType === 'three-sixty-pictures'
                    || thePictureType === 'drone-pictures'
                    || thePictureType === 'dwg-files'
                    || thePictureType === 'obj-files'
                    || thePictureType === 'rvt-files'
                    || thePictureType === 'cad-file') {
                    remainingFiles = updateFolderStructure(folderStructure, selectedPictures);

                    const result = getFilesAndFolders(folderStructure);
                    updateHasPictures(result.files.length);

                    setFolderStructure(remainingFiles);
                } else {
                    const selectedPicturesKeys = new Set(selectedPictures.map(pic => pic.Key));
                    const remainingFiles = foldersFiles.filter(file => !selectedPicturesKeys.has(file.Key));
                    setFoldersFiles(remainingFiles);
                    updateHasPictures(convertToArray(remainingFiles).length);

                }
                setShowDeleteModal(false);
                setSelectedPictures([]);


                if (thePictureType === 'scope-of-work-videos' || thePictureType === 'scope-of-work') {
                    deleteThumbnailFromS3(packageName);
                    Axios.post(`${process.env.REACT_APP_LOCALHOST}/updateFileOnDb`, {
                        packageName: packageName,
                        pictureType: pictureType,
                        filesName: '',
                    }).then((response) => {

                    }).catch(function (error) {
                        console.log('error = ', error);
                    });
                }
            }
            setTimeout(() => {
                updatePackageData();
            }, 2000)
        }).catch(function (error) {
            setShowDeleteModal(false);
            console.log('error = ', error);
        });
    };

    const deleteThumbnailFromS3 = async (packageName) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/deleteThumbnailFromPackage`, {
            packageName: packageName,
            bucketName: 'fazzad-project-videos-thumbnails',
        }).then((response) => {
            return;
        }).catch(function (error) {
            setShowDeleteModal(false);
            console.log('error = ', error);
        });
    }


    // const uploadWithSignedUrl = async (fileData, bucketName, key) => {
    //     const signedUrl = await getPutObjectSignedUrl(bucketName, key, fileData.type);
    //
    //     if (!signedUrl) {
    //         throw new Error("cannot generate signed url");
    //     }
    //
    //     const xhr = new XMLHttpRequest();
    //
    //     xhr.open('PUT', signedUrl, true);
    //     // Set the appropriate headers
    //     xhr.setRequestHeader('Content-Type', fileData.type);
    //
    //     xhr.upload.onprogress = (event) => {
    //         if (event.lengthComputable) {
    //             const progress = (event.loaded / event.total) * 100;
    //             const myTotal = progress.toString().substring(0, 4);
    //             console.log(myTotal);
    //         }
    //     };
    //
    //     xhr.send(fileData);
    //
    //     // Handle errors
    //     xhr.onerror = function (ev) {
    //         console.error('Error uploading file.', ev);
    //     };
    //
    //     xhr.onload = function () {
    //         if (xhr.status === 200) {
    //             console.log('Request completed successfully:', xhr.responseText);
    //
    //             setTimeout(() => {
    //                 initData();
    //             }, 5000);
    //
    //             // updateScopeOfWorkVideo(fileName, packageName);
    //         } else {
    //             console.error('Request failed with status:', xhr.status);
    //             throw new Error(xhr.error);
    //             //setUploadStarted(false);
    //         }
    //     };
    // }

    function updatePackageData() {
        getFileType(thePictureType);
        localStorage.setItem('currentOption', thePictureType);
        setCurrentOption(currentOption);
        getPackageImage(packageName, thePictureType, true);
    }

    const uploadPromise = async (file, packageName, currentPath) => {
        try {
            let s3BucketName = thePictureType;
            let compressedFile = '';
            let uploadPath = '';
            compressedFile = file;
            let bucketName = config.bucketName;
            const uploadFileName = cleanMediaUrl(file.name);
            if (thePictureType === 'still-pictures'
                || thePictureType === 'three-sixty-pictures'
                || thePictureType === 'drone-pictures'
                || thePictureType === 'dwg-files'
                || thePictureType === 'obj-files'
                || thePictureType === 'rvt-files'
            ) {
                // Usar currentPath en lugar de currentFolder para construir la ruta de carga
                uploadPath = currentPath.length === 0 ?
                    `${packageName}/${s3BucketName}/${uploadFileName}` :
                    `${packageName}/${s3BucketName}/${currentPath.join('/')}/${uploadFileName}`;
            } else if (thePictureType === 'scope-of-work-videos' || thePictureType === 'scope-of-work') {
                uploadPath = `${packageName}/scope-of-work-videos/${uploadFileName}`;
                // uploadPath = `${packageName}/${s3BucketName}/${uploadFileName}`;
                bucketName = configScopeOfWorkUpdateVideo.bucketName;
            } else {
                uploadPath = `${packageName}/${s3BucketName}/${uploadFileName}`;
            }

            try {
                // await uploadWithSignedUrl(compressedFile, bucketName, uploadPath)
                await uploadWithSignedUrl(compressedFile, bucketName, uploadPath, (myProgress) => {
                });

                if (thePictureType === 'dwg-files' || thePictureType === 'obj-files' || thePictureType === 'rvt-files') {
                    let signedUrlData = await AutodeskViewerHelpers.getUploadSignedUrl(
                        packageName,
                        AutodeskViewerHelpers.getFileNameFromFilePath(uploadPath, packageName, s3BucketName)
                    );

                    let uploadUrl = signedUrlData.data.urls[0];
                    let uploadKey = signedUrlData.data.uploadKey;

                    await AutodeskViewerHelpers.uploadWithSignedUrl(uploadUrl, uploadKey, compressedFile);

                    let uploadedFileData = await AutodeskViewerHelpers.finishUpload(packageName,
                        AutodeskViewerHelpers.getFileNameFromFilePath(uploadPath, packageName, s3BucketName),
                        uploadKey);

                    let objectId = uploadedFileData.data.objectId;
                    let urn = Buffer.from(objectId).toString('base64');

                    if (thePictureType === 'obj-files') {
                        if (isZipFile(compressedFile)) {
                            let rootFilename = await AutodeskViewerHelpers.getObjFilenameFromZip(compressedFile);
                            await AutodeskViewerHelpers.translateCompressedFiles(urn, rootFilename);
                        } else {
                            await AutodeskViewerHelpers.translateFiles(urn);
                        }
                    } else if (thePictureType === 'rvt-files') {
                        await AutodeskViewerHelpers.translateRevitFiles(urn);
                    } else {
                        await AutodeskViewerHelpers.translateFiles(urn);
                    }
                }
            } catch (uploadError) {
                console.error(`Upload failed for ${file.name}: ${uploadError}`);
            }
        } catch (error) {
            console.error(`Batch upload failed: ${error}`);
        }
    };

    function isZipFile(file) {
        return !(file.type !== 'application/zip' && !file.name.endsWith('.zip'));
    }

    const updateHasPictures = (remainingFile) => {
        console.log(remainingFile > 0 ? 'Has remaining files' : 'Does not have remaining files');

        Axios.post(`${process.env.REACT_APP_LOCALHOST}/updateHasPictures`, {
            packageName: packageName,
            pictureType: localStorage.getItem('currentOption'),
            hasPictures: remainingFile > 0 ? true : false
        }).then(() => {
            return;
        }).catch(function (error) {
            console.log('error = ', error);
        });
    };

    const deleteCurrentFilesFromS3 = async (packageName, pictureType) => {
        let bucketName = 'fazzad-projects';
        if (pictureType !== 'cover-image') {
            bucketName = 'fazzad-projects-videos';
        }
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/deleteCurrentFile`, {
            packageName: packageName,
            bucketName: bucketName,
            pictureType: pictureType
        }).then((response) => {
            if (typeof response.data.Deleted !== 'undefined' && response.data.Deleted.length > 0) {
                setShowDeleteModal(false);
                setSelectedPictures([]);
                updatePackageData();
            }
        }).catch(function (error) {
            setShowDeleteModal(false);
            console.log('error = ', error);
        });
    };

    const clearFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleUpload = async () => {
        try {
            setShowModal(false);
            setShowProcessing(true);
            if (!showWrongImageFormat) {
                if (selectedPictures.length > 0) {
                    if (thePictureType === 'cover-image' || thePictureType === 'scope-of-work') {
                        await deleteCurrentFilesFromS3(packageName, thePictureType);
                    }
                    if (thePictureType === 'scope-of-work-videos' || thePictureType === 'scope-of-work') {
                        await deleteThumbnailFromS3(packageName);
                    }
                    setShowUploadModal(true);
                    try {
                        let fileIndex = 0;
                        for (const pict of selectedPictures) {
                            fileIndex++;
                            setCurrentIndex(fileIndex);
                            // Pasar currentPath en lugar de currentFolder
                            await uploadPromise(pict, packageName, currentPath);
                        }
                        if (thePictureType === 'cover-image' || thePictureType === 'scope-of-work') {
                            updateCurrentFilesOnDb(packageName, thePictureType, selectedPictures);
                        }
                        setSelectedPictures([]);
                        updateHasPictures(selectedPictures.length);

                        clearFileInput();

                        setTimeout(() => {
                            updatePackageData();
                        }, 3000);
                    } catch (e) {
                        setSelectedPictures([]);
                        clearFileInput();
                        console.error(`Uploading file error: ${e}`);
                    } finally {
                        setShowUploadModal(false);
                    }
                } else {
                    setSelectedPictures([]);
                    setShowModal(false);
                    setShowUploadModal(false);
                }
            }
        } catch (e) {
            setSelectedPictures([]);
            setShowModal(false);
            clearFileInput();
        }
    }

    const getPageTitle = (currentOption) => {
        switch (currentOption) {
            case 'plan-picture':
                return {
                    headerText: `${edit ? 'Edit' : 'Upload'} Plan & Picture`,
                    subheaderText: `All fields are required unless marked as optional`,
                }
            default:
                return {
                    headerText: `${edit ? 'Edit' : 'Upload'} ${pictureTypeDisplay}`,
                    subheaderText: `Add or remove ${pictureTypeDisplay}`,
                }
        }
    }

    const checkForDisable = (pictureType) => {
        let isDisabled = false;
        if (pictureType === '360-pictures' || pictureType === 'still-pictures') {
            if (currentFolder === '' || selectedPictures.length === 0) {
                isDisabled = true;
            }
        } else {
            isDisabled = selectedPictures.length === 0;
        }
        return isDisabled;
    }

    const hasKey = (selectedPictures) => {
        return Object.values(selectedPictures).some(pic => pic.hasOwnProperty('Key'));
    }

    const checkForChecked = (selectedPictures, file) => {
        if (hasKey(selectedPictures)) {
            return selectedPictures.some(f => f.Key === file.Key);
        }
    }

    const getText = (pictureType) => {
        if (pictureType === 'cover-image' || pictureType === 'scope-of-work') {
            return 'Replace';
        } else {
            return 'Upload';
        }
    };

    const clearFolderName = (name) => {
        const folderName = name.trim();
        setNewFolderName(folderName);
    }

    const handleChange = (event) => {
        setDuplicatedFolderName('');
        const newValue = event.target.value;
        // Check if newValue matches the regex pattern
        if (/^[a-zA-Z0-9\s]*$/.test(newValue)) {
            setNewFolderName(newValue); // Update the state if the input is valid
        }
    };

    const checkFolderName = (folders, name) => {
        return folders.some(folder => folder.name.toLowerCase() === name.toLowerCase());
    };

    const handleAddFolderConfirm = async () => {
        // setShowDuplicatedFolderNameMessage(false);
        // const newFolder = {id: Date.now(), name: newFolderName, subfolders: [], selectedFiles: []};
        // const updatedStructure = [...folderStructure, newFolder];
        // if (checkFolderName(folderStructure, newFolderName)) {
        //     // setShowDuplicatedFolderNameMessage(true);
        //     setDuplicatedFolderName(newFolderName);
        //     return;
        // }
        if (newFolderName === '') {
            setHasFolderName(false);
            return;
        }

        let data = {
            packageName: packageName,
            pictureType: thePictureType,
            currentPatch: currentPath.length > 0 ? currentPath.join('/').concat(`/${newFolderName}`) : newFolderName,
        };

        let response = await Axios.post(`${process.env.REACT_APP_LOCALHOST}/package/createPackageFolderType`, data);

        setTimeout(() => {
            updatePackageData();
        }, 1000)

        setShowNewFolderModal(false);
        setNewFolderName('');
    };

    const handleDeleteFolderConfirm = async (pathToDelete) => {
        let data = {
            packageName: packageName,
            pictureType: thePictureType,
            currentPatch: pathToDelete.join('/'),
        };

        let response = await Axios.post(`${process.env.REACT_APP_LOCALHOST}/package/deletePackageFolderPath`, data);

        setShowLoading(true);

        const remainingStructure = {...folderStructure};
        let currentLevel = remainingStructure;

        for (let i = 0; i < pathToDelete.length - 1; i++) {
            currentLevel = currentLevel[pathToDelete[i]];
        }

        delete currentLevel[pathToDelete[pathToDelete.length - 1]];

        const result = getFilesAndFolders(remainingStructure);

        updateHasPictures(result.files.length);

        setTimeout(() => {
            updatePackageData();
        }, 6000);

        setShowDeleteFolderModal(false);
    }

    return (
        <div>
            <Header/>
            {selectedPdf && (
                <PdfThumbnailsModal onClose={() => setSelectedPdf(undefined)} selectedPdfData={selectedPdf}
                                    allUsedPhotos={allUsedPhotos}/>
            )}
            {showModal && (
                <div id="modal-5" className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4">Are you sure you want to
                                upload {selectedPictures.length} {selectedPictures.length > 1 ? 'files ' : 'file '} ?</h2>
                        </div>
                        <div className="modal__action">
                            <div className="button-group">
                                <button className="button button-m button-secondary" type="button" onClick={() => {
                                    setShowModal(false);
                                    setSelectedPictures([]);
                                    // Limpiar el input de archivos cuando se cancela
                                    clearFileInput();
                                }}>No, Cancel
                                </button>
                                <button className="button button-m button-primary" type="button" onClick={() => {
                                    handleUpload()
                                }}>Yes, Upload
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showDeleteModal && (
                <div id="modal-5" className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4">Are you sure you want to
                                delete {selectedPictures.length} file{selectedPictures.length > 1 ? 's ?' : ' ?'}</h2>
                        </div>
                        <div className="modal__content">
                            <p>Once a file is deleted it cannot be recovered. You will need to upload it again.</p>
                        </div>
                        <div className="modal__action">
                            <div className="button-group">
                                <button className="button button-m button-secondary" type="button" onClick={() => {
                                    setShowDeleteModal(false)
                                }}>No, Cancel
                                </button>
                                <button className="button button-m button-destructive" type="button"
                                        onClick={() => deleteFilesFromS3()}
                                >Yes, Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showDeleteFolderModal && (
                <div id="modal-5" className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4">Are you sure you want to
                                delete the folder</h2>
                        </div>
                        <div className="modal__content">
                            <p>Once a file is deleted it cannot be recovered. You will need to upload it again.</p>
                        </div>
                        <div className="modal__action">
                            <div className="button-group">
                                <button className="button button-m button-secondary" type="button" onClick={() => {
                                    setShowDeleteFolderModal(false)
                                }}>No, Cancel
                                </button>
                                <button className="button button-m button-destructive" type="button"
                                        onClick={() => handleDeleteFolderConfirm(folderToDelete)}
                                >Yes, Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showUploadModal ?
                <div id="modal-6" className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4">File Upload Progress</h2>
                        </div>
                        <div className="modal__content">
                            {showProcessing && (
                                <div className="alert-container">
                                    <div className="alert alert--informational">
                                        <div className="alert__content">
                                            <div className="alert-inline__icontitle">
                                                <p className="alert__heading">{process.env.REACT_APP_PROCESSING_MESSAGE_WAIT} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="file-uploads" style={{marginTop: 16}}>
                                <section>
                                    {currentIndex < selectedPictures.length && selectedPictures.length > 0 ?
                                        currentIndex > 0 ?
                                            <>
                                                <h5>{pictureTypeDisplay}</h5>
                                                <div
                                                    className="message"> Uploading {uploadTypeText} {currentIndex} of {selectedPictures.length}<span
                                                    className="loader loader--s"></span></div>
                                            </>
                                            :
                                            ''
                                        :
                                        currentIndex === selectedPictures.length && selectedPictures.length > 0 && (
                                            // <div className='message fz-align-center'>
                                            //     <p>All {uploadTypeText}s have been uploaded!</p>
                                            //     {<span className="icon">
                                            //         <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            //              xmlns="http://www.w3.org/2000/svg">
                                            //             <path
                                            //                 d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                            //                 stroke="#007000" strokeWidth="2" strokeLinecap="round"
                                            //                 strokeLinejoin="round"/>
                                            //         </svg>
                                            //     </span>}
                                            // </div>

                                            <>
                                                <h5>{pictureTypeDisplay}</h5>
                                                <div
                                                    className="message"> Uploading {uploadTypeText} {selectedPictures.length} of {selectedPictures.length}<span
                                                    className="loader loader--s"></span></div>
                                            </>
                                        )}
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                : ''
            }

            <main id="fazzad-main">
                <div id="app-container">
                    <nav className="breadcrumbs-container" aria-label="Breadcrumbs">
                        <ol>
                            <li>
                                <a href="/" onClick={(e) => {
                                    e.preventDefault();
                                    goToFeed();
                                }}>Home</a>
                                <span className="separator">
                                    {getSvg('chevron-right')}
                                </span>
                            </li>
                            <li>
                                <a href="/" onClick={(e) => {
                                    e.preventDefault();
                                    navigate(`/editpackage/${packageName}`);
                                }}>Package Details</a>
                                <span className="separator">
                                    {getSvg('chevron-right')}
                                </span>
                            </li>
                            <li aria-current="page">
                                <span>{pictureTypeDisplay}</span>
                            </li>
                        </ol>
                    </nav>
                    <div id='title'>
                        <PageTitle
                            headerText={getPageTitle(currentOption).headerText}
                            subHeaderText={getPageTitle(currentOption).subheaderText}
                            showSubHeader={true}
                        />
                        {currentOption !== 'plan-picture' && <div className="button-group">
                            <div className="form-field file-input">
                                {/*{currentFolder !== '' ?*/}
                                <><input
                                    ref={fileInputRef}
                                    type="file"
                                    id="newpicture"
                                    className="file-input__input"
                                    data-multiple-caption='{count} files selected'
                                    multiple={thePictureType !== 'cover-image' && thePictureType !== 'scope-of-work'}
                                    onChange={updateFilesSelected}/><label htmlFor="newpicture"
                                                                           className="button button-s button-tertiary">
                                    {getSvg('upload')}
                                    <span className="text">{getText(thePictureType)}</span>
                                    {/*<span className="text">{getText(thePictureType)} {pictureTypeDisplay}</span>*/}
                                </label></>
                                {/*:*/}
                                {/*<button disabled type="button" className="button-destructive-outlined button-s">*/}
                                {/*    {getSvg('upload')}*/}
                                {/*    <span className="text">Upload {pictureTypeDisplay}</span>*/}
                                {/*</button>*/}
                                {/*}*/}
                            </div>
                            {currentOption === 'Still Pictures'
                            || currentOption === '360 Pictures'
                            || currentOption === 'Drone Pictures'
                            || currentOption === 'DWG File' ?
                                <div className="form-field file-input">
                                    <button type="button" className="button button-s button-tertiary"
                                            onClick={() => setShowNewFolderModal(true)}>
                                        {getSvg('folder')}
                                        {/*<span className="text">New {pictureTypeDisplay} folder</span>*/}
                                        <span className="text">New folder</span>
                                    </button>
                                </div> : ''
                            }
                            <div className="form-field file-input">
                                <button type="button" className="button-destructive-outlined button-s"
                                        onClick={() => checkForDeletion()} disabled={checkForDisable(pictureType)}>
                                    {getSvg('delete')}
                                    <span className="text">Delete</span>
                                    {/*<span className="text">Delete Selected {uploadTypeText}s</span>*/}
                                </button>
                            </div>
                        </div>}
                    </div>
                    <div id="folder-container">
                        <section className="container-nav__layout">
                            {showWrongImageFormat ?
                                <div id="modal-2" className="modal-container">
                                    <div className="modal">
                                        <div className="modal__header">
                                            <div className="alert-container">
                                                <div className="alert alert--error">
                                                    <div className="alert__content">
                                                        <div className="alert-inline__icontitle">
                                                            <p className="alert__heading">Something wrong with the files
                                                                you selected.</p>
                                                        </div>
                                                        <p className="alert__message">{errorMessage}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="button"
                                                    className="button-tertiary button-s button-icon modal-close"
                                                    onClick={() => {
                                                        setSelectedPictures([]);
                                                        setShowWrongImageFormat(false);
                                                        // Limpiar el input de archivos cuando hay error de formato
                                                        clearFileInput();
                                                    }}>
                                                {getSvg('close')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                : ''
                            }
                            {showNoFilesToDelete ?
                                <div id="modal-2" className="modal-container">
                                    <div className="modal">
                                        <div className="modal__header">
                                            {getSvg('warning')}
                                            <h2 className="modal-title h4 warning">You have not selected any files to be
                                                deleted</h2>
                                            <button type="button"
                                                    className="button-tertiary button-s button-icon modal-close"
                                                    onClick={() => {
                                                        setShowNoFilesToDelete(false)
                                                    }}>
                                                {getSvg('close')}
                                            </button>
                                        </div>
                                        <div className="modal__content">
                                            <p>In order to delete files, you need to select at least one file first.</p>
                                        </div>
                                    </div>
                                </div>
                                : ''
                            }
                            <aside>
                                <nav className="nav-file-type">
                                    <h3 className={'h5'}>Pictures</h3>

                                    <ul>
                                        <li className={currentOption === 'Cover Image' ? "current" : "item"}>
                                            <a href="" onClick={(e) => {
                                                e.preventDefault();
                                                localStorage.setItem('currentOption', 'cover-image');
                                                getFileType('cover-image');
                                                getPackageImage(packageName, 'cover-image');
                                                setSelectedPictures([]);
                                            }}>Cover Image</a>
                                        </li>
                                        <li className={currentOption === 'Still Pictures' ? "current" : "item"}>
                                            <a href="" onClick={(e) => {
                                                e.preventDefault();
                                                getFileType('still-pictures');
                                                localStorage.setItem('currentOption', 'still-pictures');
                                                setCurrentOption('Still Pictures');
                                                getPackageImage(packageName, 'still-pictures');
                                                setSelectedPictures([]);
                                            }}>Still</a>
                                        </li>
                                        <li className={currentOption === '360 Pictures' ? "current" : "item"}>
                                            <a href="" onClick={(e) => {
                                                e.preventDefault();
                                                getFileType('three-sixty-pictures');
                                                localStorage.setItem('currentOption', 'three-sixty-pictures');
                                                setCurrentOption('360 Pictures');
                                                getPackageImage(packageName, 'three-sixty-pictures')
                                                setSelectedPictures([]);
                                            }}>360</a>
                                        </li>
                                        <li className={currentOption === 'Drone Pictures' ? "current" : "item"}>
                                            <a href="" onClick={(e) => {
                                                e.preventDefault();
                                                getFileType('drone-pictures');
                                                localStorage.setItem('currentOption', 'drone-pictures');
                                                setCurrentOption('Drone Pictures');
                                                getPackageImage(packageName, 'drone-pictures');
                                                setSelectedPictures([]);
                                            }}>Drone</a>
                                        </li>
                                    </ul>

                                    <h3 className={'h5'}>Video</h3>

                                    <ul>
                                        <li className={currentOption === 'Walkthrough Video' ? "current" : "item"}>
                                            <a href="" onClick={(e) => {
                                                e.preventDefault();
                                                localStorage.setItem('currentOption', 'scope-of-work');
                                                getFileType('scope-of-work');
                                                setCurrentOption('Walkthrough Video');
                                                getPackageImage(packageName, 'scope-of-work');
                                                setSelectedPictures([]);
                                            }}>Walkthrough Video</a>
                                        </li>
                                    </ul>

                                    <h3 className={'h5'}>PDFs</h3>

                                    <ul>
                                        <li className={currentOption === 'PDF Files' ? "current" : "item"}>
                                            <a href="" onClick={(e) => {
                                                e.preventDefault();
                                                getFileType('pdf-files');
                                                localStorage.setItem('currentOption', 'pdf-files');
                                                setCurrentOption('PDF Files');
                                                getPackageImage(packageName, 'pdf-files')
                                                setSelectedPictures([]);
                                            }}>PDF Files</a>
                                        </li>

                                        <li className={(currentOption === 'plan-picture' ? "current" : "item") + " no-mobile"}>
                                            <a href="" onClick={(e) => {
                                                e.preventDefault();
                                                getFileType('pdf-files');
                                                localStorage.setItem('currentOption', 'plan-picture');
                                                setCurrentOption('plan-picture')
                                                getPackageImage(packageName, 'pdf-files')
                                            }}>Plan & Picture</a>
                                        </li>
                                    </ul>

                                    <h3 className={'h5'}>Autodesk Viewer</h3>

                                    <ul>
                                        <li className={currentOption === 'DWG File' ? "current" : "item"}>
                                            <a href="" onClick={(e) => {
                                                e.preventDefault();
                                                getFileType('cad-file');
                                                localStorage.setItem('currentOption', 'dwg-files');
                                                setCurrentOption('DWG File');
                                                getPackageImage(packageName, 'dwg-files');
                                                setSelectedPictures([]);
                                            }}>.dwg</a>
                                        </li>
                                        <li className={currentOption === 'RVT File' ? "current" : "item"}>
                                            <a href="" onClick={(e) => {
                                                e.preventDefault();
                                                getFileType('rvt-files');
                                                localStorage.setItem('currentOption', 'rvt-files');
                                                setCurrentOption('RVT File');
                                                getPackageImage(packageName, 'rvt-files');
                                                setSelectedPictures([]);
                                            }}>.rvt</a>
                                        </li>
                                        <li className={currentOption === 'OBJ File' ? "current" : "item"}>
                                            <a href="" onClick={(e) => {
                                                e.preventDefault();
                                                getFileType('obj-files');
                                                localStorage.setItem('currentOption', 'obj-files');
                                                setCurrentOption('OBJ File');
                                                getPackageImage(packageName, 'obj-files');
                                                setSelectedPictures([]);
                                            }}>.obj / .zip</a>
                                        </li>
                                    </ul>
                                </nav>
                            </aside>
                            {(() => {
                                switch (currentOption) {
                                    case "Still Pictures":
                                    case "360 Pictures":
                                    case "Drone Pictures":
                                    case "DWG File":
                                    case "OBJ File":
                                    case "RVT File":
                                        return (
                                            <div className="uploaded-file-grid">
                                                {showLoading && <div className="loading-screen">
                                                    <div className="demo-container" style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        gap: '16px'
                                                    }}>
                                                        <p>Loading files...</p>
                                                        <p className="loader loader--s"></p>
                                                    </div>
                                                </div>}
                                                <RenderContent
                                                    currentPath={currentPath}
                                                    updatePath={updatePath}
                                                    structureData={folderStructure}
                                                    resetPath={resetPath}
                                                    pictureTypeDisplay={pictureTypeDisplay}
                                                    selectedPictures={selectedPictures}
                                                    thePictureType={thePictureType}
                                                    bucketPrefix={bucketPrefix}
                                                    addSelectedPicture={addSelectedPicture}
                                                    packageData={packageData}
                                                    setPackageData={setPackageData}
                                                    deleteFolder={(path) => {
                                                        setFolderToDelete(path);
                                                        setShowDeleteFolderModal(true);
                                                    }}
                                                />
                                            </div>
                                        )
                                            ;
                                    case "Plan & Picture":
                                    case 'plan-picture':
                                        if (!showLoading && foldersFiles.length > 0) {
                                            return (
                                                <PdfList onEdit={setSelectedPdf} items={foldersFiles}
                                                         setAllUsedPhotos={setAllUsedPhotos}/>
                                            );
                                        }
                                        return <p>No {pictureTypeDisplay} available for this package.</p>
                                    default:
                                        return (
                                            <div className="form-container" key={`key_div_${currentOption}`}>
                                                {foldersFiles.length > 0 ? (
                                                    <div className="pic-grid" key={`pcigr${currentOption}`}>
                                                        {showLoading ? (
                                                            <div className="loading-screen">
                                                                <div className="demo-container">
                                                                    <p>Loading files...</p>
                                                                    <span className="loader loader--m"></span>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            foldersFiles.map((file, index) => {
                                                                const fileName = file.Key.split("/").pop();
                                                                if (thePictureType === "scope-of-work") {
                                                                    scopeOfWorkVideoUrl = `${s3BucketPrefixVideo}/fazzad-thumbnail-00001-${packageName}/scope-of-work/${fileName}`;
                                                                    scopeOfWorkVideoUrlVideo = `${videoBucketPreview}/${packageName}/scope-of-work/${fileName.replace(/\.\w+$/, ".mp4")}`;
                                                                }
                                                                const mainClass = "uploaded-file__preview";
                                                                const secondClass = "icon-image";
                                                                const fullClass =
                                                                    thePictureType === "pdf-files" ||
                                                                    thePictureType === "dwg-files" ||
                                                                    thePictureType === "obj-files" ||
                                                                    thePictureType === "rvt-files"
                                                                        ? `${mainClass} ${secondClass}`
                                                                        : mainClass;
                                                                console.log(thePictureType)
                                                                const pictureType = thePictureType === ViewType.scopeOfWork ? ViewType.scopeOfWorkThumbnail : thePictureType
                                                                return (
                                                                    <figure className="upload-file"
                                                                            key={`afsgures_${index}`}>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={`label_checkbox_${index}`}
                                                                            value={file.imagePath}
                                                                            key={`acheckboxsa_${index}`}
                                                                            onChange={(e) => addSelectedPicture(e, file)}
                                                                            checked={checkForChecked(selectedPictures, file)}
                                                                        />
                                                                        <label htmlFor={`label_checkbox_${index}`}
                                                                               key={`alabel_${index}`}>
                                                                            <span className={fullClass}
                                                                                  key={`aspan_${index}`}>
                                                                                {thePictureType === "pdf-files" && getSvg("pdf")}
                                                                                {thePictureType === "dwg-files" && getSvg("cad-illustration")}
                                                                                {thePictureType === "obj-files" && getSvg("cad-illustration")}
                                                                                {thePictureType === "rvt-files" && getSvg("cad-illustration")}
                                                                                {thePictureType !== "pdf-files"
                                                                                    && thePictureType !== "dwg-files"
                                                                                    && thePictureType !== "obj-files"
                                                                                    && thePictureType !== "rvt-files" && (
                                                                                        <ImageWithPresignedUrl
                                                                                            fileKey={file.Key}
                                                                                            bucketName={getBucketNameByViewType(pictureType)}
                                                                                            fileType={getFileExt(file.Key)}
                                                                                            setPreSignedUrl={(url) => setPackageData(data => ({
                                                                                                ...data,
                                                                                                [pictureType]: url
                                                                                            }))}
                                                                                            imageUrl={null}
                                                                                            // imageUrl={packageData[pictureType] ?? null}
                                                                                            key={`aimag_${index}`}
                                                                                        />
                                                                                    )}
                                                                            </span>
                                                                            <figcaption key={`figs${index}`}>
                                                                                {fileName} - {thePictureType}
                                                                                &nbsp;
                                                                                {thePictureType === "scope-of-work" && (
                                                                                    <a
                                                                                        href=""
                                                                                        key={`ahaaref_${index}`}
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            setShowPreviewModal(true);
                                                                                        }}
                                                                                    >
                                                                                        Preview &raquo;
                                                                                    </a>
                                                                                )}
                                                                            </figcaption>
                                                                        </label>
                                                                    </figure>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                ) : !showLoading ? (thePictureType === 'scope-of-work' ?
                                                        <p>No Walkthrough Video is currently available for this
                                                            package. If you have uploaded a Walkthrough and it
                                                            is not appearing here, please note that it may still be
                                                            processing. Check back later to confirm its
                                                            availability.</p> :
                                                        <p>No {pictureTypeDisplay} available for this package.</p>
                                                ) : (
                                                    <div className="loading-screen">
                                                        <div className="demo-container">
                                                            <p>Loading files...</p>
                                                            <span className="loader loader--m"></span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                }
                            })()}
                        </section>
                        <nav id="step-controls">
                            <div className="button-group">
                                <button
                                    type="button"
                                    className="button-m button-tertiary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate(`/editpackage/${packageName}`);
                                    }}
                                >
                                    <span className="text">
                                        Back
                                    </span>
                                </button>
                            </div>
                        </nav>
                        {showPreviewModal && (
                            <div id="modal-2" className="modal-container">
                                <div className="modal">
                                    <div className="modal__header">
                                        <button
                                            type="button"
                                            className="button-tertiary button-s button-icon modal-close"
                                            onClick={() => setShowPreviewModal(false)}
                                        >
                                            {getSvg('close')}
                                        </button>
                                    </div>
                                    <div className="modal__content">
                                        <div className="video rounded" key={`video_`}>
                                            <VideoWithPresignedUrl
                                                fileKey={`${packageName}/scope-of-work-videos/${foldersFiles[0]?.Key?.split("/")?.pop()?.replace(/\.\w+$/, ".mp4")}`}
                                                bucketName={getBucketNameByViewType(thePictureType)}
                                                fileType={'mp4'}
                                                setPreSignedUrl={(url) => setPackageData(data => ({
                                                    ...data,
                                                    [thePictureType]: url
                                                }))}
                                                postUrl={packageData[ViewType.scopeOfWorkThumbnail] ?? null}
                                                videoUrl={packageData[thePictureType] ?? null}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {showNewFolderModal && (
                            <div id="modal-5" className="modal-container">
                                <div className="modal">
                                    <div className="modal__content">
                                        <div className="form-field text-input">
                                            <label htmlFor="new-folder-name">New Folder Name</label>
                                            <input id="new-folder-name" type="text" value={newFolderName}
                                                   onChange={handleChange}
                                                   onBlur={(e) => clearFolderName(e.target.value)}
                                                   placeholder="Folder name"/>
                                        </div>
                                    </div>
                                    {duplicatedFolderName !== '' && (
                                        <div className="alert alert--error">
                                            <div className="alert__content">
                                                <p className="alert__heading">The folder name "{duplicatedFolderName}"
                                                    already exists. Please choose a different name.</p>
                                            </div>
                                            <button type="button"
                                                    className="button-tertiary button-s button-icon alert__close"
                                                    onClick={() => setDuplicatedFolderName('')}>
                                    <span className="icon">
                                      {getSvg('close')}
                                    </span>
                                            </button>
                                        </div>

                                    )}
                                    {!hasFolderName && (
                                        <div className="alert alert--error">
                                            <div className="alert__content">
                                                <p className="alert__heading">Please enter a folder name.</p>
                                            </div>
                                            <button type="button"
                                                    className="button-tertiary button-s button-icon alert__close"
                                                    onClick={() => setHasFolderName(true)}>
                                    <span className="icon">
                                      {getSvg('close')}
                                    </span>
                                            </button>
                                        </div>

                                    )}
                                    <div className="modal__action">
                                        <div className="button-group">
                                            <button className="button button-m button-secondary" type="button"
                                                    onClick={handleAddFolderConfirm}>Create
                                            </button>
                                            <button className="button button-m button-primary" type="button"
                                                    onClick={() => {
                                                        setShowNewFolderModal(false);
                                                        setHasFolderName(true);
                                                        setDuplicatedFolderName('')
                                                    }}>Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </main>
        </div>
    )
}

function PageBreadcrumbs({path, onCrumbClick, resetPath, pictureTypeDisplay, folderStructure}) {
    const isEmpty = Object.keys(folderStructure).length === 0;

    // if (isEmpty) {
    //     return (<p>No {pictureTypeDisplay} available for this package.</p>)
    // }
    return (
        <nav className="breadcrumbs-container" aria-label="Breadcrumbs">
            <ol>
                <li>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        resetPath()
                    }}>{pictureTypeDisplay}</a>
                    {path.length > 0 && <span className="separator">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                  strokeLinejoin="round"></path>
                        </svg>
                    </span>}
                </li>

                {path.map((crumb, index) => (
                    <li key={index}>
                        {index !== path.length - 1 ?
                            <a href="" onClick={(e) => {
                                e.preventDefault();
                                onCrumbClick(path.slice(0, index + 1));
                            }}>
                                {crumb}
                            </a>
                            : crumb
                        }
                        {index !== path.length - 1 && <span className="separator">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round"></path>
                            </svg>
                        </span>}
                    </li>
                ))}
            </ol>
        </nav>
    );
}

// Function to render the content of the current folder
function RenderContent({
                           updatePath,
                           structureData,
                           resetPath,
                           pictureTypeDisplay,
                           selectedPictures,
                           thePictureType,
                           bucketPrefix,
                           addSelectedPicture,
                           currentPath,
                           setPackageData,
                           packageData,
                           deleteFolder,
                       }) {
    const currentItem = currentPath?.reduce((acc, key) => acc[key], structureData);

    if (!currentItem || currentItem._isFile) {
        // If it's a file or the path is incorrect, you can decide how to handle this.
        // For now, let's just return null or some fallback.
        return null;
    } else {
        // Sort keys to display folders first, then files
        let sortedKeys = Object.keys(currentItem).sort((a, b) => {
            const isAFile = currentItem[a]._isFile;
            const isBFile = currentItem[b]._isFile;
            return isAFile === isBFile ? 0 : isAFile ? 1 : -1;
        });

        sortedKeys = sortedKeys.reduce((acc, val) => {
            if (val) {
                acc.push(val);
            }
            return acc;
        }, []);

        return (
            <>
                <PageBreadcrumbs path={currentPath} onCrumbClick={updatePath} resetPath={resetPath}
                                 pictureTypeDisplay={pictureTypeDisplay} folderStructure={structureData}/>

                <div className="pic-grid">
                    {sortedKeys.length > 0 ? (
                        sortedKeys.map((key, index) => {
                            const item = currentItem[key];
                            if (!item._isFile) {
                                // For folders, allow navigation into the folder
                                return (
                                    <figure key={`figure_${index}`} style={{position: 'relative'}}>
                                        <a href="" onClick={(e) => {
                                            e.preventDefault();
                                            updatePath([...currentPath, key]); // Navigate into the folder
                                        }}>
                                            <img src={graphicFolder} alt={key}/>
                                            <figcaption>{key}</figcaption>
                                        </a>
                                        <button
                                            title="Delete"
                                            className="button button-icon button-s"
                                            style={{position: "absolute", top: 0, right: 0, padding: '4px'}}
                                            onClick={(e) => {
                                                e.preventDefault();

                                                // console.log([...currentPath, key]);
                                                deleteFolder([...currentPath, key])
                                                // setSingleVideo(video);
                                                // setShowDeleteModal(true)
                                            }}
                                        >
                                            <img style={{objectFit: 'contain'}} src={trashicon} alt={'Delete'}/>
                                        </button>
                                    </figure>
                                );
                            } else {
                                // For files, display the file without modal interaction
                                return (
                                    <figure className="upload-file" key={`figures_${index}`}
                                            style={(thePictureType === "dwg-files" || thePictureType === "obj-files" || thePictureType === "rvt-files") && sortedKeys.length === 1 ? {minWidth: '234px'} : {}}>
                                        <input
                                            type="checkbox"
                                            id={`label_checkbox_${index}`}
                                            value={key}
                                            key={`checkbox_s${index}`}
                                            onChange={(e) => addSelectedPicture(e, item)}
                                            checked={Array.isArray(selectedPictures) && selectedPictures.includes(item)}
                                        />
                                        <label htmlFor={`label_checkbox_${index}`} key={`labels_s_${index}`}>
                                            <span className="uploaded-file__preview" key={`spanner_${index}`}>
                                                {thePictureType === "dwg-files" || thePictureType === "obj-files" || thePictureType === "rvt-files" ?
                                                    <div>
                                                        <img
                                                            style={{
                                                                objectFit: 'contain',
                                                                objectPosition: 'center',
                                                                padding: '32px'
                                                            }}
                                                            alt={key}
                                                            src={
                                                                thePictureType === 'dwg-files' ?
                                                                    iconDwg : thePictureType === 'obj-files' ? iconObj : thePictureType === 'rvt-files' ? iconRvt : iconDwg
                                                            }
                                                        />
                                                    </div>
                                                    :
                                                    <ImageWithPresignedUrl
                                                        fileKey={item.Key}
                                                        bucketName={getBucketNameByViewType(thePictureType)}
                                                        fileType={getFileExt(item.Key)}
                                                        setPreSignedUrl={(url) => setPackageData(data => ({
                                                            ...data,
                                                            [thePictureType]: url
                                                        }))}
                                                        imageUrl={null}
                                                    />
                                                }
                                                <figcaption>{key}</figcaption>
                                            </span>
                                        </label>
                                    </figure>
                                );
                            }
                        })
                    ) : (
                        <p>No {pictureTypeDisplay} available for this package.</p>
                    )}
                </div>
            </>
        );
    }
}
